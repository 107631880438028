import house_training from './lessons/house-training.js'
import transitions from './transitions.js'
import chapter_intros from './chapter_intros.js'
import equipment from './equipment.js'
import checkins from './checkins.js'
import quizzes from './quizzes.js'
import videos from './videos.js'
import skillbuilders from './skillbuilders.js'

let courses = [
  {
    name: 'Puppy Kindergarden',
    id: 'puppy-kindergarden',
    course_lessons: [house_training],
  },
]

let cards_database = [
  ...transitions,
  ...chapter_intros,
  ...equipment,
  ...checkins,
  ...quizzes,
  ...videos,
  ...skillbuilders,
]

export function getCourses() {
  return courses
}

export function getCourse(courseId) {
  return getCourses().find((course) => course.id === courseId)
}

export function getLessons(courseId) {
  let course = getCourses().find((course) => course.id === courseId)
  return course.course_lessons
}

export function getLesson(courseId, lessonId) {
  return getLessons(courseId).find((lesson) => lesson.id === lessonId)
}

export function getCardByID(cardId) {
  return cards_database.find((card) => card['ID'] === cardId)
}
