import * as React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation'
import Paper from '@mui/material/Paper'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { ReactComponent as HomeIcon } from '../../assets/home_icon.svg'
import { ReactComponent as ShopIcon } from '../../assets/shop_icon.svg'
import { ReactComponent as ChatIcon } from '../../assets/chat_icon.svg'

import { ReactComponent as HomeActiveIcon } from '../../assets/home_active_icon.svg'
import { ReactComponent as ShopActiveIcon } from '../../assets/shop_active_icon.svg'
import { ReactComponent as ChatActiveIcon } from '../../assets/chat_active_icon.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import SvgIcon from '@mui/material/SvgIcon'


export default function StandardBottomNavigation() {
  let location = useLocation()
  let defaultValue = 'Home'
  if (location.pathname == '/shop') {
    defaultValue = 'Shop'
  }
  let onboardingState = localStorage.getItem('onboarding')
  onboardingState = JSON.parse(onboardingState)
  const [isChatOpen, setIsChatOpen] = React.useState(false)
  const [value, setValue] = React.useState(defaultValue)
  let navigate = useNavigate()

  React.useEffect(() => {
    if (isChatOpen) {
      setValue("Chat")
    } else if (location.pathname == '/shop') {
      setValue("Shop")
    } else {
      setValue("Home")
    }
  }, [location, isChatOpen])

  const targetNode = document.getElementById('slaask-widget')

  React.useEffect(() => {
    if (targetNode) {
      const config = { attributes: true, childList: true, subtree: true }
      const observer = new MutationObserver(() => {
        if (targetNode.className) {
          if (targetNode.className.includes('slaask-widget-loaded')) {
            setIsChatOpen(true)
          } else {
            setIsChatOpen(false)
          }
        }
      })
      observer.observe(targetNode, config)
    }
  }, [targetNode])

  const handleChange = (event, newValue) => {
    if (value === "Chat") {
      window._slaask.hide()
    }
    setValue(newValue)

    if (newValue === 'Home') {
      if (onboardingState && Object.keys(onboardingState).length >= 4) {
        navigate('/course/puppy-kindergarden/lessons/house-training')
      } else {
        navigate('/onboarding')
      }
      setIsChatOpen(false)
    } else if (newValue === 'Shop') {
      navigate('/shop')
      setIsChatOpen(false)
    } else {
      setIsChatOpen(true)
    }
  }

  return (<>{isChatOpen ?
    <div style={{
      background: "#2F4818",
      width: "100%",
      maxWidth: "475px",
      padding: "9px",
      zIndex: 100000000000000,
      position: "absolute",
      top: 0,
    }} id="someDiv">

    </div> : null}
    <Paper
      className={'notch-space-bottom'}
      style={{ zIndex: isChatOpen ? 9999999999 : 1 }}
      sx={{
        boxShadow: 'none',
        borderTop: '1px solid rgba(0,0,0,.25)',
        borderRadius: 0,
        backgroundColor: 'rgba(228,219,198,100)',
        position: 'fixed',
        bottom: 0,
        height: 75,
        zIndex: 10,
        width: '100%',
        maxWidth: "474px",
      }}
      elevation={3}
    >
      <BottomNavigation
        className={'notch-space-bottom-bar-insert'}
        showLabels
        sx={{ backgroundColor: 'rgba(228,219,198,100)', width: '100%', position: 'absolute', bottom: 8 }}
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction
          label="Shop"
          value="Shop"
          disableRipple
          icon={
            <SvgIcon
              width={28}
              height={24}
              component={value === 'Shop' ? ShopActiveIcon : ShopIcon}
              viewBox="0 0 38 34"
            />
          }
        />
        <BottomNavigationAction
          label="Home"
          value="Home"
          disableRipple
          icon={
            <SvgIcon
              width={28}
              height={24}
              component={value === 'Home' ? HomeActiveIcon : HomeIcon}
              viewBox="0 0 38 34"
            />
          }
        />
        <BottomNavigationAction
          className="slaask-open-widget"
          label="Chat"
          value="Chat"
          disableRipple
          icon={
            <SvgIcon
              width={28}
              height={24}
              component={value === 'Chat' ? ChatActiveIcon : ChatIcon}
              viewBox="0 0 38 34"
            />
          }
        />
      </BottomNavigation>
    </Paper>
  </>)
}
