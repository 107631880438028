import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import SoundIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import Loader from '../ui/Loader'

let audio = null

export default function CustomCard({
    maxSteps,
    action,
    isPaused,
    card,
    step,
    index,
    setCurrentCard,
    next,
    config,
    messageHandler,
}) {
    const cardType = card.assetUrl.includes('.mp4') ? 'video' : 'image'

    const vidRef = React.useRef(null)
    const [loaded, setLoaded] = React.useState(false)
    const [isMuted, setIsMuted] = React.useState(true)
    const [isPlayed, setIsPlayed] = React.useState(false)

    React.useEffect(() => {
        audio = new Audio(card.audio)
        action("pause")

        return () => {
            audio.pause()
        }
    }, [])

    React.useEffect(() => {
        if (vidRef.current) {
            if (isPaused) {
                vidRef.current.pause()
            } else {
                vidRef.current.play().catch(() => { })
            }
        }
    }, [isPaused])

    const videoLoaded = () => {
        audio.load()
        messageHandler('UPDATE_VIDEO_DURATION', { duration: vidRef.current.duration * 3 })
        setLoaded(true)
        action("play")
    }

    const playAudio = () => {
        if (card.audio) {
            audio.play().catch((e) => {
                console.log(e);
            });

            setIsMuted(false)
        }
    }

    const muteAudio = () => {
        audio.muted = true
        audio.pause()
        audio.currentTime = 0
        setIsMuted(true)
    }

    const unmuteAudio = () => {
        if (isPlayed) {
            audio.muted = false
            audio.play()
            setIsMuted(false)
        } else {
            setIsPlayed(true)
            playAudio()
        }
    }

    return (
        <Box sx={{ width: '100%', height: '100vh' }} key={step}>
            <Box
                sx={{
                    position: 'absolute',
                    background: 'rgb(0,0,0)',
                    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%)',
                    width: '100%',
                    bottom: 'env(safe-area-inset-bottom)', // calc(2px + env(safe-area-inset-bottom))
                    padding: '48px 24px 48px 24px',
                    zIndex: 1000,
                }}
            >
                <div
                    onClick={() => isMuted ? unmuteAudio() : muteAudio()}
                >
                    {
                        isMuted ? <VolumeOffIcon style={{ fill: "white" }} /> : <SoundIcon style={{ fill: "white" }} />
                    }
                </div>
                <Typography
                    sx={{
                        color: 'white',
                        fontSize: 24,
                        fontWeight: 300,
                        fontFamily: 'buena-park-jf',
                    }}
                    align="left"
                    variant="h5"
                    gutterBottom
                    component="div"
                >
                    {card.headline}
                </Typography>
                <Typography
                    sx={{
                        color: 'white',
                        fontWeight: 400,
                        fontSize: 16,
                        fontFamily: 'basic-sans',
                    }}
                    align="left"
                    variant="h5"
                    gutterBottom
                    component="div"
                >
                    {card.body}
                </Typography>
                {maxSteps - 1 === index && (
                    <Box>
                        <Button
                            className="bottom-notch-space"
                            onClick={() => setCurrentCard(next)}
                            sx={{
                                width: '100%',
                                backgroundColor: '#f46e4a',
                                borderRadius: 0,
                                fontFamily: 'basic-sans, sans-serif',
                                color: '#392D2C',
                                fontSize: 14,
                                padding: '12px 48px',
                                marginTop: '12px',
                                fontWeight: 700,
                                boxShadow: 'none',
                                zIndex: 9999,
                                '&:hover': {
                                    backgroundColor: '#F46E4A',
                                },
                            }}
                            disableRipple
                            variant="contained"
                        >
                            {card.button_text || "CONTINUE"}
                        </Button>
                    </Box>
                )}
            </Box>

            <Box
                style={{
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    width: "100%",
                    height: "20vh",
                    position: 'absolute',
                    background: 'rgb(0,0,0)',
                    background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%)',
                }}
            />

            {cardType === 'video' && (
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100vh',
                    left: 0,
                    zIndex: 1,
                }}>
                    <video
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%',
                            height: '100vh',
                            left: 0,
                            opacity: 1,
                            zIndex: 1,
                        }}
                        ref={vidRef}
                        onLoadedData={videoLoaded}
                        playsInline
                        muted={card.audio ? true : false}
                        webkit-playsinline="true"
                        controls={false}
                        loop={true}
                    >
                        <source src={`${card.assetUrl}`} type="video/mp4" />
                    </video>
                </div>
            )}
            {cardType === 'image' && (
                <img
                    alt={card.headline}
                    src={`${card.assetUrl}?random`}
                    width={'100%'}
                    style={{
                        top: 0,
                        zIndex: 1,
                        objectFit: 'cover',
                        opacity: 1,
                        maxHeight: '100%',
                        minHeight: '100%',
                    }}
                />
            )}

            <Box
                sx={{ display: loaded ? "none" : "flex", justifyContent: "center", alignItems: "center", height: "100%", textAlign: 'center' }}
            >
                <Loader />
            </Box>
        </Box>
    )
}
