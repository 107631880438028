import * as React from 'react'
import { useParams } from 'react-router-dom'
import { getLesson, getCardByID } from '../courses_config/data.js'

import CheckInCard from '../components/chapter_templates/CheckinCard.js'
import FinalCheckInCard from '../components/chapter_templates/FinalCheckInCard.js'
import TransitionCard from '../components/chapter_templates/TransitionCard'
import StuffYouNeed from '../components/chapter_templates/StuffYouNeed'
import SkillBuilder from '../components/chapter_templates/SkillBuilder'
import VideoCard from '../components/chapter_templates/VideoCard'
import QuizPathCard from '../components/chapter_templates/QuizPathCard'
import ChapterIntro from '../components/chapter_templates/ChapterIntro'
import LessonTOC from '../components/LessonTOC'
import { useNavigate } from 'react-router-dom'

let componentsMap = {
  CheckInCard: CheckInCard,
  FinalCheckInCard: FinalCheckInCard,
  TransitionCard: TransitionCard,
  StuffYouNeed: StuffYouNeed,
  SkillBuilder: SkillBuilder,
  VideoCard: VideoCard,
  ChapterIntro: ChapterIntro,
}

export default function Lesson() {
  let onboardingState = localStorage.getItem('onboarding')
  onboardingState = JSON.parse(onboardingState)
  let navigate = useNavigate()

  React.useEffect(() => {
    if (!window.LOGGED_IN) {
      navigate('/locked')
    }
    if (!onboardingState && window.LOGGED_IN) {
      navigate('/onboarding')
    }
  }, [navigate, onboardingState])

  let params = useParams()
  let lesson = getLesson(params.courseId, params.lessonId)

  const ls_card_keys = localStorage.getItem(`${params.courseId}__${params.lessonId}`)
  const ls_progress_keys = localStorage.getItem(`${params.courseId}__${params.lessonId}_progress`)
  const [cards, setCards] = React.useState(ls_card_keys ? JSON.parse(ls_card_keys) : { card_keys: {} })
  const [card_progress, setCardProgress] = React.useState(ls_progress_keys ? JSON.parse(ls_progress_keys) : [])
  const [currentChapter, setCurrentChapter] = React.useState(-1)
  const [currentCard, setCurrentCard] = React.useState(0)
  const [backOverride, setBackOverride] = React.useState(false)
  const [showTransition, setShowTransition] = React.useState(false)

  function skipToChapter(newChapter) {
    setBackOverride(true)
    changeChapter(newChapter)
  }

  function changeChapter(newChapter) {
    setCurrentCard(0)
    var new_progress = card_progress
    new_progress[currentChapter] = true
    localStorage.setItem(`${params.courseId}__${params.lessonId}_progress`, JSON.stringify(new_progress))

    localStorage.setItem(`${params.courseId}__${params.lessonId}_current_chapter`, newChapter)

    localStorage.setItem(`${params.courseId}__${params.lessonId}_last_completed_card_in_chapter`, 0)
    setCardProgress(new_progress)
    setCurrentChapter(newChapter)
    window.scrollTo(0, 0)
  }

  function changeCard(newCard) {
    if (newCard === -3) {
      let chapters = getChapterCards(getLessonKey()).chapters
      if (currentChapter + 1 < chapters.length) {
        setBackOverride(false)
        changeChapter(currentChapter + 1)
        return
      }
    } else if (newCard === -1 && !backOverride) {
      if (currentChapter - 1 > 0) {
        changeChapter(currentChapter - 1)
        return
      }
    }
    localStorage.setItem(`${params.courseId}__${params.lessonId}_current_chapter`, currentChapter)
    localStorage.setItem(`${params.courseId}__${params.lessonId}_last_completed_card_in_chapter`, currentCard)
    setCurrentCard(newCard)
    window.scrollTo(0, 0)
  }

  function changeState(index, answer) {
    var new_state = cards.card_keys
    new_state[String(index)] = answer
    localStorage.setItem(`${params.courseId}__${params.lessonId}`, JSON.stringify({ card_keys: new_state }))
    setCards({ card_keys: new_state })
    if (Object.keys(new_state).length === lesson.onboarding_quiz.length && lesson.onboarding_quiz.length) {
      setShowTransition(true);
    }
  }

  function clearLocalStorage() {
    localStorage.removeItem(`${params.courseId}__${params.lessonId}`)
    localStorage.removeItem(`${params.courseId}__${params.lessonId}_progress`)
    localStorage.removeItem(`${params.courseId}__${params.lessonId}_current_chapter`)
    localStorage.removeItem(`${params.courseId}__${params.lessonId}_last_completed_card_in_chapter`)
    setCards({ card_keys: {} })
    setCardProgress([])
  }

  function renderQuiz() {
    return <QuizPathCard quiz={lesson.onboarding_quiz} onChange={changeState} coursePath={`/onboarding`} />
  }

  function next() {
    let chapter_cards = getChapterCards(getLessonKey()).chapters[currentChapter].chapter_cards
    if (currentCard + 1 === chapter_cards.length) {
      return -3
    } else {
      return currentCard + 1
    }
  }

  function prev() {
    return currentCard - 1 <= -1 ? -1 : currentCard - 1
  }

  function getLessonKey() {
    return Object.values(cards.card_keys).join('_')
  }

  function getChapterCards(lesson_key) {
    return lesson.lesson_paths.find((lesson_path) => lesson_path.lesson_key === lesson_key)
  }

  function renderLesson() {
    if (currentCard >= 0 && currentChapter >= 0) {
      let chapter_cards = getChapterCards(getLessonKey()).chapters[currentChapter].chapter_cards
      let current_card = chapter_cards[currentCard]
      const Component = componentsMap[current_card.type]

      if (current_card.type === 'SkillBuilder') {
        return (
          <Component
            setCurrentCard={changeCard}
            next={next()}
            prev={prev()}
            skillBuilderCards={current_card.skillBuilderCards}
          />
        )
      } else if (current_card.type === 'StuffYouNeed') {
        return (
          <Component
            setCurrentCard={changeCard}
            next={next()}
            prev={prev()}
            stuffYouNeedCards={current_card.stuffYouNeedCards}
          />
        )
      } else {
        current_card.properties = getCardByID(current_card['ID'])

        if (current_card.type === "VideoCard") {
          return (
            <Component setCurrentCard={changeCard} next={next()} prev={prev()} {...current_card.properties} />
          )
        } else {
          return <Component setCurrentCard={changeCard} next={next()} prev={prev()} {...current_card.properties} />
        }
      }
    } else {
      return (
        <LessonTOC
          coursePath={`/course/${params.courseId}/lessons`}
          card_progress={card_progress}
          clearLocalStorage={clearLocalStorage}
          setCurrentChapter={changeChapter}
          skipToChapter={skipToChapter}
          lesson={getChapterCards(getLessonKey())}
        />
      )
    }
  }

  let trn_current_card = getCardByID('TRN02')

  return (
    <div id="main-lessun">
      {
        showTransition ?
          <TransitionCard color="#fff" setCurrentCard={() => setShowTransition(false)} backbutton={false} next={() => { }} prev={() => { }} {...trn_current_card} />
          :
          Object.keys(cards.card_keys).length !== Object.keys(lesson.onboarding_quiz).length
            ? renderQuiz()
            : renderLesson()
      }
    </div>
  )
}
