let checkins = [
    {
        "Page": "Check In 01 A1",
        "ID": "CHK01A1",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "0d960998-912f-495d-bf20-c30685a54ce4"
    },
    {
        "Page": "Check In 01 A2",
        "ID": "CHK01A2",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "0d960998-912f-495d-bf20-c30685a54ce4"
    },
    {
        "Page": "Check In 01 B1",
        "ID": "CHK01B1",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "0d960998-912f-495d-bf20-c30685a54ce4"
    },
    {
        "Page": "Check In 01 B2",
        "ID": "CHK01B2",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "0d960998-912f-495d-bf20-c30685a54ce4"
    },
    {
        "Page": "Check In 02 A1",
        "ID": "CHK02A1",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "6390bd7b-52da-428e-9ba5-7f385dafaa68"
    },
    {
        "Page": "Check In 02 A2",
        "ID": "CHK02A2",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "b479c899-fcdf-4fe2-9063-23348aa8b847"
    },
    {
        "Page": "Check In 02 B1",
        "ID": "CHK02B1",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "d754381b-abbe-443c-92c9-c0817815f9cf"
    },
    {
        "Page": "Check In 02 B2",
        "ID": "CHK02B2",
        "question": "How's it going so far?",
        "response_a": "Good",
        "answer_a": "Glad to hear it!",
        "response_b": "Not so good",
        "answer_b": "chat",
        "chat_path_id": "88b787df-dfb8-4266-ab25-3b203aa62642"
    },
    {
        "Page": "Check In 03 A1",
        "ID": "CHK03A1",
        "question": "Congrats on finishing House Training!",
        "response_a": "Back to Home",
        "body_a": "Refer back to this course as you work with your dog – and as always, you can reach out to chat with one of our certified trainers if you have any questions or need more help.",
        "response_b": "Chat with a Trainer",
        "answer_b": "chat",
        "chat_path_id": "0343f4bd-27c3-4411-974f-6ed42ccc0cc4"
    },
    {
        "Page": "Check In 03 A2",
        "ID": "CHK03A2",
        "question": "Congrats on finishing House Training!",
        "response_a": "Back to Home",
        "body_a": "Refer back to this course as you work with your dog – and as always, you can reach out to chat with one of our certified trainers if you have any questions or need more help.",
        "response_b": "Chat with a Trainer",
        "answer_b": "chat",
        "chat_path_id": "1d906211-16be-4b0c-bde3-fcd0da433011"
    },
    {
        "Page": "Check In 03 B1",
        "ID": "CHK03B1",
        "question": "Congrats on finishing House Training!",
        "response_a": "Back to Home",
        "body_a": "Refer back to this course as you work with your dog – and as always, you can reach out to chat with one of our certified trainers if you have any questions or need more help.",
        "response_b": "Chat with a Trainer",
        "answer_b": "chat",
        "chat_path_id": "82feb1ca-f3f5-4cf7-aff0-bb79cbdb2152"
    },
    {
        "Page": "Check In 03 B2",
        "ID": "CHK03B2",
        "question": "Congrats on finishing House Training!",
        "response_a": "Back to Home",
        "body_a": "Refer back to this course as you work with your dog – and as always, you can reach out to chat with one of our certified trainers if you have any questions or need more help.",
        "response_b": "Chat with a Trainer",
        "answer_b": "chat",
        "chat_path_id": "2ac211a3-a7d7-4dcf-aab8-0ac0db7750f1"
    }
]

function Checkins() {
    return checkins
}

export default Checkins()
