import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import SvgIcon from '@mui/material/SvgIcon'
import { styled } from '@mui/material/styles'
import { Themes, ThemeNames } from './themes'
import TextField from '@mui/material/TextField'
import { PickerDropPane, PickerInline, PickerOverlay } from 'filestack-react'
import Typography from '@mui/material/Typography'
import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@mui/material/MobileStepper'
import { ReactComponent as Star } from '../../assets/star.svg'
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import { DESKTOP_WIDTH } from '../constants'

let theme_names = ThemeNames()
let themes = Themes()
let onboardingQuizCardTheme = Math.floor(Math.random() * theme_names.length)

const CssTextField = styled(TextField)({
  '& label': {
    color: themes[theme_names[onboardingQuizCardTheme]].textColor,
    fontFamily: 'basic-sans',
  },
  '& label.Mui-focused': {
    color: themes[theme_names[onboardingQuizCardTheme]].textColor,
    fontFamily: 'basic-sans',
    borderColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    fontFamily: 'basic-sans',
    color: themes[theme_names[onboardingQuizCardTheme]].textColor,
    textAlign: 'center',
    '& fieldset': {
      fontFamily: 'basic-sans',
      borderColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
    },
    '&:hover fieldset': {
      borderColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
    },
    '&.Mui-focused fieldset': {
      borderColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
    },
  },
})

export default function OnboardingQuizCard({ quiz, next, prev, setCurrentCard }) {
  const onboardingState = localStorage.getItem('onboarding')
  const [activeStep, setActiveStep] = React.useState(0)
  const [state, setState] = React.useState(onboardingState ? JSON.parse(onboardingState) : {})
  const [image, setImage] = React.useState(onboardingState ? JSON.parse(onboardingState)['image'] : null)
  const maxSteps = quiz.length

  React.useEffect(() => {
    localStorage.setItem("theme", onboardingQuizCardTheme)
  }, [])

  const choiceChosen = (index, card) => {
    handleNext()
    if (index === maxSteps - 1) {
      setCurrentCard(next)
    }
  }

  const updateState = (event) => {
    let newState = { ...state }
    newState[event.target.id] = event.target.value
    localStorage.setItem('onboarding', JSON.stringify(newState))

    if (window.memberstack_member) {
      window.memberstack_member.updateMetaData(newState)
    }
    setState(newState)
  }

  const updateImage = (res) => {
    if (res.filesUploaded.length > 0) {
      let img = res.filesUploaded[0].url
      setImage(img)
      let newState = { ...state }
      newState['image'] = img
      localStorage.setItem('onboarding', JSON.stringify(newState))
      if (window.memberstack_member) {
        window.memberstack_member.updateMetaData(newState)
      }
      setState(newState)
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  const renderTextInput = (quiz, index) => {


    return (
      <Box sx={{ padding: '10px 0 10px 0' }}>
        <CssTextField
          sx={{
            borderColor: 'white',
            color: 'white',
            borderRadius: 0,
            width: '100%',
            margin: '20px 0 20px 0',
          }}
          onChange={updateState}
          id={quiz.formValue}
          label={quiz.formText}
          variant="outlined"
        />
        <Button
          onClick={() => {
            if (state[quiz.formValue] && state[quiz.formValue].length > 0) {
              choiceChosen(index, next)
            }
          }}

          sx={{
            width: '100%',
            backgroundColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
            borderRadius: 0,
            fontFamily: 'buena-park-jf',
            color: themes[theme_names[onboardingQuizCardTheme]].buttonTextColor,
            fontSize: 18,
            padding: '12px 48px',
            fontWeight: 300,
            boxShadow: 'none',
            textTransform: 'Capitalize',
            opacity: !state[quiz.formValue] ? 0.3 : 1,
            '&:hover': {
              backgroundColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
            },
          }}
          disableRipple
          variant="contained"
        >
          {quiz.button1Text}
        </Button>
      </Box>
    )
  }

  const removePhoto = () => {
    setImage(null);

    let newState = { ...state }
    delete newState['image'];
    localStorage.setItem('onboarding', JSON.stringify(newState))
    if (window.memberstack_member) {
      window.memberstack_member.updateMetaData(newState)
    }
    setState(newState)
  }

  const renderPhotoInput = (quiz, index) => {
    return (
      <Box
        component="form"
        sx={{
          padding: '10px 0 10px 0',
        }}
      >
        {image ? null : <PickerDropPane
          onSuccess={updateImage}
          pickerOptions={{
            accept: 'image/*',
            dropPane: { customText: 'choose an image' },
          }}
          apikey="AWevDf9qqSgueCtcXmXhKz"
        />
        }
        {image && (
          <img alt="Your Dog" src={image} style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }} />
        )}
        <Button
          onClick={() => {
            choiceChosen(index, next)
          }}
          sx={{
            width: '100%',
            backgroundColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
            borderRadius: 0,
            fontFamily: 'buena-park-jf',
            color: themes[theme_names[onboardingQuizCardTheme]].buttonTextColor,
            fontSize: 18,
            padding: '12px 48px',
            fontWeight: 300,
            boxShadow: 'none',
            textTransform: 'Capitalize',
            '&:hover': {
              backgroundColor: themes[theme_names[onboardingQuizCardTheme]].buttonBg,
            },
          }}
          disableRipple
          variant="contained"
        >
          {quiz.button1Text}
        </Button>
        {image && (
          <>
            <p
              onClick={removePhoto}
              style={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "center",
                letterSpacing: ".5px",
                fontFamily: 'buena-park-jf',
                color: themes[theme_names[onboardingQuizCardTheme]].textColor,
              }}
            >
              Upload a different photo
            </p>
          </>
        )}
      </Box>
    )
  }

  return (
    <Box
      className={'notch-space real-full-height'}
      sx={{
        backgroundImage: themes[theme_names[onboardingQuizCardTheme]].backgroundImage,
        backgroundRepeat: 'repeat',
        backgroundColor: themes[theme_names[onboardingQuizCardTheme]].backgroundColor,
        height: '100%',
        width: '100%',
        maxWidth: DESKTOP_WIDTH,
        position: 'fixed',
        zIndex: 100,
      }}
    >
      <Grid container sx={{ padding: '25px', zIndex: 1 }}>
        <Grid item xs={2} justifyContent="center" alignItems="center">
          {activeStep >= 1 ? <Box style={{ textDecoration: 'none' }} onClick={() => setActiveStep((prev) => prev - 1)}>
            <SvgIcon
              sx={{ width: 24, height: 16, path: { fill: themes[theme_names[onboardingQuizCardTheme]].textColor } }}
              component={BackIcon}
              viewBox="0 0 24 17"
            />
          </Box> : null}
        </Grid>
        <Grid item xs={8} justifyContent="center" alignItems="center">
          <MobileStepper
            className={themes[theme_names[onboardingQuizCardTheme]].dotsClass}
            steps={maxSteps}
            position="static"
            sx={{
              justifyContent: 'center',
              maxWidth: 400,
              backgroundColor: 'transparent',
            }}
            activeStep={activeStep}
          />
        </Grid>
        <Grid item xs={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              fontFamily: 'basic-sans',
              color: themes[theme_names[onboardingQuizCardTheme]].textColor,
            }}
            align="center"
            variant="h5"
            gutterBottom
            component="div"
          >
            {activeStep + 1}/{maxSteps}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ zIndex: 1, display: "flex", alignItems: "center", height: "85vh" }}>
          <SwipeableViews axis={'x'} index={activeStep} disabled={true} onChangeIndex={handleStepChange}>
            {quiz.map((quiz, index) => {
              return (
                <div>
                  <Grid key={quiz.ID} item xs={12}>
                    <Grid item xs={12} justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
                      <SvgIcon
                        sx={{
                          width: 24,
                          height: 36,
                          path: {
                            fill: "white",
                          },
                        }}
                        component={Star}
                        viewBox="0 0 24 36"
                      />
                    </Grid>
                    <Typography
                      sx={{
                        color: themes[theme_names[onboardingQuizCardTheme]].textColor,
                        fontWeight: 400,
                        fontSize: "32px",
                        lineHeight: "38px",
                        fontFamily: 'buena-park-jf',
                      }}
                      align="center"
                      variant="h5"
                      gutterBottom
                      component="div"
                    >
                      {quiz.question}
                    </Typography>
                  </Grid>
                  {quiz.form === 'Text Input' && renderTextInput(quiz, index)}
                  {quiz.form === 'Upload Pic' && renderPhotoInput(quiz, index)}
                </div>
              )
            })}
          </SwipeableViews>
        </Grid>
      </Grid>
    </Box>
  )
}
