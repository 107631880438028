import equipment from "../../courses_config/equipment"

export function swipeHandler(el, callback) {
    const handletouch = callback || function (evt, dir, phase, swipetype, distance) { };

    if (el) {
        var touchsurface = el,
            dir,
            dist,
            swipeType,
            startX,
            startY,
            distX,
            distY,
            threshold = 150, //required min distance traveled to be considered swipe
            restraint = 100, // maximum distance allowed at the same time in perpendicular direction
            allowedTime = 500, // maximum time allowed to travel that distance
            elapsedTime,
            startTime

        touchsurface.addEventListener('touchstart', function (e) {
            var touchobj = e.changedTouches[0]
            dir = 'none'
            swipeType = 'none'
            dist = 0
            startX = touchobj.pageX
            startY = touchobj.pageY
            startTime = new Date().getTime() // record time when finger first makes contact with surface
            handletouch(e, 'none', 'start', swipeType, 0) // fire callback function with params dir="none", phase="start", swipetype="none" etc
            // e.preventDefault()
        }, false)
        touchsurface.addEventListener('touchmove', function (e) {
            var touchobj = e.changedTouches[0]
            distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
            if (Math.abs(distX) > Math.abs(distY)) { // if distance traveled horizontally is greater than vertically, consider this a horizontal movement
                dir = (distX < 0) ? 'left' : 'right'
                handletouch(e, dir, 'move', swipeType, distX) // fire callback function with params dir="left|right", phase="move", swipetype="none" etc
            }
            else { // else consider this a vertical movement
                dir = (distY < 0) ? 'up' : 'down'
                handletouch(e, dir, 'move', swipeType, distY) // fire callback function with params dir="up|down", phase="move", swipetype="none" etc
            }
            // e.preventDefault() // prevent scrolling when inside DIV
        }, false)
        touchsurface.addEventListener('touchend', function (e) {
            var touchobj = e.changedTouches[0]
            elapsedTime = new Date().getTime() - startTime // get time elapsed
            if (elapsedTime <= allowedTime) { // first condition for awipe met
                if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) { // 2nd condition for horizontal swipe met
                    swipeType = dir // set swipeType to either "left" or "right"
                }
                else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) { // 2nd condition for vertical swipe met
                    swipeType = dir // set swipeType to either "top" or "down"
                }
            }
            // Fire callback function with params dir="left|right|up|down", phase="end", swipetype=dir etc:
            handletouch(e, dir, 'end', swipeType, (dir == 'left' || dir == 'right') ? distX : distY)
            // e.preventDefault()
        }, false)
    } else {
        handletouch(null, null, '', '', 0)
    }
}

export function getEquipments() {
    let option = ""
    let lesson_path = localStorage.getItem("puppy-kindergarden__house-training", "{}")
    lesson_path = JSON.parse(lesson_path)
    lesson_path = lesson_path.card_keys;
    if (lesson_path) {
        Object.keys(lesson_path).map(key => {
            option = option + "_" + lesson_path[key]
        })
    }

    switch (option) {
        case "_Crate_Outside":
            return [
                equipment[0],
                equipment[1],
                equipment[2],
                equipment[3],
                equipment[6],
                equipment[7],
                equipment[8],
                equipment[9],
                equipment[10],
            ]

        case "_Crate_PottyPad":
            return [
                equipment[0],
                equipment[1],
                equipment[2],
                equipment[3],
                equipment[4],
                equipment[5],
                equipment[6],
                equipment[7],
                equipment[8],
                equipment[9],
                equipment[10],
            ]

        case "_NoCrate_Outside":
            return [
                equipment[1],
                equipment[2],
                equipment[3],
                equipment[4],
                equipment[5],
                equipment[6],
                equipment[7],
                equipment[8],
                equipment[9],
                equipment[10],
            ]

        case "_NoCrate_PottyPad":
            return [
                equipment[1],
                equipment[2],
                equipment[3],
                equipment[4],
                equipment[5],
                equipment[6],
                equipment[7],
                equipment[8],
                equipment[9],
                equipment[10],
            ]

        default:
            return equipment;
    }
}