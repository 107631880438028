import * as React from 'react'
import Link from '@mui/material/Link'

import Button from '@mui/material/Button'
import Box from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export default function FinalCheckInCard({
  problems_array,
  text,
  get_in_touch_url,
  next_text,
  next,
  prev,
  setCurrentCard,
}) {
  return (
    <Grid container>
      <Grid item xs={2} justifyContent="center" alignItems="center" sx={{ padding: '24px 24px 48px 24px', zIndex: 1 }}>
        <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(prev)}>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 700,
              fontFamily: 'serif',
              color: 'white',
              textDecoration: 'none',
            }}
            align="left"
            variant="h5"
            gutterBottom
            component="div"
          >
            {'<'}
          </Typography>
        </Box>
      </Grid>
      <img
        alt="placeholder dog"
        src="https://placedog.net/700/500"
        width={'100%'}
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 0,
          objectFit: 'cover',
          opacity: 1,
          overflow: 'hidden',
        }}
      />

      <Grid item xs={12} sx={{ zIndex: 1, paddingTop: '120px' }}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: 'rgba(228,219,198,1)',
            borderRadius: 0,
            padding: '24px',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                sx={{ color: 'rgba(47,38,37,1)', fontSize: 24, fontWeight: 700, fontFamily: 'serif' }}
                align="left"
                variant="p"
                gutterBottom
                component="div"
              >
                {text}
              </Typography>
              <Typography
                sx={{ color: 'rgba(47,38,37,1)', fontWeight: 400, fontSize: 14, fontFamily: 'serif' }}
                align="left"
                variant="h5"
                gutterBottom
                component="div"
              >
                {text}
              </Typography>
            </Grid>

            <Grid container>
              {problems_array.map((problem) => (
                <Grid item xs={12} sx={{ padding: '12px 0 12px 0' }}>
                  <Grid container align="center">
                    <Grid item xs={12}>
                      <Box>
                        {problem.label}
                        <Link href={problem.url}>{problem.url_text}</Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <Box>
                Something else? <Link href={get_in_touch_url}> Get In Touch</Link>
              </Box>
            </Grid>

            <Grid
              sx={{ padding: '24px 12px 48px 12px', zIndex: 1, textAlign: 'right' }}
              item
              xs={12}
              justifyContent="right"
              alignItems="end"
            >
              <Button
                onClick={() => setCurrentCard(next)}
                sx={{
                  width: '100%',
                  backgroundColor: '#f46e4a',
                  borderRadius: 0,
                  fontFamily: 'basic-sans, sans-serif',
                  color: '#fff',
                  fontSize: 14,
                  padding: '12px 48px',
                  fontWeight: 700,
                  boxShadow: 'none',
                }}
                variant="contained"
              >
                {next_text}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
