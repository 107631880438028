import { Mixpanel } from '../Mixpanel'
import * as React from 'react'
import Container from '@mui/material/Container'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LogoIcon from '../assets/GB_Salmon.svg'
import { ReactComponent as BackIcon } from '../assets/back_icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import { getEquipments } from '../components/ui/utils'

export default function Shop({ setCurrentCard, prev }) {
  let equipment_list = getEquipments()

  const renderItem = (item, index) => {
    return (
      <Card raised={false} sx={{ maxWidth: 158, height: '100%', borderRadius: 0, boxShadow: 'none', margin: "0 auto" }}>
        <CardMedia sx={{ objectFit: 'contain' }} component="img" height="100" image={item.image} alt={item.name} />
        <CardContent style={{ minHeight: "95px" }}>
          <Typography
            sx={{
              color: '#392D2C)',
              fontSize: 14,
              lineHight: 21,
              fontWeight: 700,
              fontFamily: 'basic-sans',
            }}
            align="center"
            variant="h5"
            gutterBottom
            component="div"
          >
            {item.name}
          </Typography>
          <Typography
            sx={{
              color: '#392D2C)',
              fontSize: 14,
              lineHight: 14,
              fontWeight: 300,
              fontFamily: 'basic-sans',
            }}
            align="center"
            variant="h5"
            gutterBottom
            component="div"
          >
            {item.price}
          </Typography>
        </CardContent>
        <CardActions>
          <Link
            href={item.link}
            style={{
              textAlign: 'center',
              width: '100%',
              backgroundColor: '#EFA329',
              borderRadius: 0,
              fontFamily: 'basic-sans',
              color: '#392D2C',
              fontSize: 12,
              padding: '12px 12px',
              fontWeight: 700,
              textDecoration: 'none',
              '&:hover': {
                backgroundColor: '#F46E4A',
              },
            }}
            disableRipple
            variant="contained"
            target="_blank"
          >
            Buy Now
          </Link>
        </CardActions>
      </Card>
    )
  }

  Mixpanel.track('Viewed Shop')
  return (
    <Container
      className={'real-full-height-2x'}
      disableGutters={true}
      sx={{ width: '100%', backgroundColor: 'rgba(246,242,234,1)', paddingBottom: '100px' }}
    >
      <Grid className={'notch-space'} container sx={{ width: '100%', backgroundColor: '#EFA329' }}>
        {
          prev ?
            <Grid
              item
              justifyContent="center"
              alignItems="center"
              xs={4}
              sx={{ padding: '24px 24px 12px 24px', textAlign: 'center' }}
            >
              <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(prev)}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                    fontFamily: 'serif',
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  align="left"
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  <SvgIcon
                    sx={{ width: 24, height: 16, path: { fill: '#392D2C' } }}
                    component={BackIcon}
                    viewBox="0 0 24 17"
                  />
                </Typography>
              </Box>
            </Grid>
            :
            null
        }

        <Grid
          item
          justifyContent="center"
          alignItems="center"
          xs={prev ? 4 : 12}
          sx={{ padding: '29px 24px 12px 24px', textAlign: 'center' }}
        >
          <img alt="Gentle Beast" style={{ width: 100, fill: '#392D2C' }} src={LogoIcon} />
        </Grid>

        {
          prev ?
            <Grid item xs={4} />
            :
            null
        }
      </Grid>

      <Grid container sx={{ width: '100%', padding: '24px', backgroundColor: 'rgba(246,242,234,1)' }}>
        <Typography
          sx={{
            color: '#392D2C',
            fontSize: 24,
            lineHight: 32,
            fontFamily: 'buena-park-jf',
            padding: '0 0 24px 0',
          }}
          align="left"
          variant="h5"
          gutterBottom
          component="div"
        >
          Get all the equipment you need for the course
        </Typography>
        <Grid rowSpacing={1.5} columnSpacing={1.5} container>
          {equipment_list.map((item, index) => (
            <Grid key={`shop-item-${index}`} item xs={6}>
              {renderItem(item, index)}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}
