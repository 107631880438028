import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Accordion from '../ui/Accordion'
import Typography from '@mui/material/Typography'
import StuffImg from '../../assets/stuff_you_need.png'
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import { useNavigate } from 'react-router-dom'
import { EQUIPMENT_CHECKLIST, DESKTOP_WIDTH } from '../constants'
import { getEquipments } from '../ui/utils'

export default function StuffYouNeed({ stuffYouNeedCards, next, prev, setCurrentCard }) {
  const navigate = useNavigate()
  let equipment_list = getEquipments()

  let onboardingState = localStorage.getItem('onboarding')
  onboardingState = JSON.parse(onboardingState)
  var steps = stuffYouNeedCards ?? equipment_list.map((eq) => eq.ID)

  const backHandler = () => {
    if (prev) {
      setCurrentCard(prev)
    } else {
      navigate(-1)
    }
  }

  return (
    <Grid container sx={{ height: '100%', backgroundColor: 'rgba(228,219,198,100)' }}>
      <Grid
        item
        xs={6}
        justifyContent="center"
        alignItems="center"
        sx={{ position: 'absolute', padding: '24px', zIndex: 100 }}
      >
        <Box style={{ textDecoration: 'none' }} onClick={backHandler}>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 700,
              color: '#EFA329',
              textDecoration: 'none',
              fontFamily: 'buena-park-jf',
            }}
            align="left"
            variant="h5"
            gutterBottom
            component="div"
          >
            <SvgIcon
              sx={{ width: 24, height: 16, path: { fill: '#EFA329' } }}
              component={BackIcon}
              viewBox="0 0 24 17"
            />
          </Typography>
        </Box>
      </Grid>

      <Box
        style={{
          top: 0,
          left: 0,
          zIndex: 2,
          width: "100%",
          height: "20vh",
          position: 'absolute',
          background: 'rgb(0,0,0)',
          background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%)',
        }}
      />

      <img
        alt="banner image"
        src={EQUIPMENT_CHECKLIST}
        height={'auto'}
        width={'100%'}
        height={300}
        style={{
          maxWidth: DESKTOP_WIDTH,
          top: 0,
          zIndex: 0,
          opacity: 1,
          objectFit: "cover",
          position: 'absolute',
          overflow: 'hidden',
        }}
      />

      <Grid item align="center" xs="12" sx={{ height: '100%', position: 'relative', }} className="stuff-notch-space">
        <Box
          sx={{
            zIndex: 10,
            height: '100%',
            borderRadius: 0,
            border: 'none',
            boxShadow: 'none',

          }}
        >
          <Box sx={{ padding: '24px', }}   >
            <Typography

              sx={{
                color: '#392D2C',
                fontSize: 24,
                fontWeight: 400,
                lineHeight: '31.2px',
                fontFamily: 'buena-park-jf',
              }}
              align="left"
              variant="p"
              gutterBottom
              component="div"
            >
              Here’s what you need
            </Typography>

            <Typography
              sx={{
                color: '#392D2C',
                fontWeight: 400,
                fontSize: 16,
                fontFamily: 'basic-sans',
                marginBottom: 5,
              }}
              align="left"
              variant="h5"
              gutterBottom
              component="div"
            >
              We’ve pulled together a list of all the tools and equipment that will help you be successful in house training, alongside some links to our favorite brands.
            </Typography>

            {steps.map((step, index) => (
              <Accordion step={step} key={index} />
            ))}

            {next ? (
              <Box sx={{ padding: '24px 0 ', width: '100%' }}>
                <button
                  className="bottom-notch-space"
                  style={{
                    backgroundColor: '#EFA329',
                    color: '#392D2C',
                    border: 0,
                    fontWeight: 700,
                    width: '100%',
                    padding: '16px',
                    fontSize: 16,
                    margin: '0 auto',
                  }}
                  onClick={() => setCurrentCard(next)}
                >
                  Continue
                </button>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
