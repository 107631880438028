let lesson = {
    title: 'House Training',
    description: 'House training is a foundational behavior that can be taught at any age – but the earlier, the better. We\'ll break down what you\'ll need know to be as successful as possible.',
    id: 'house-training',
    onboarding_quiz: [
        {
            question: 'Will you be using a crate to train?',
            details: `Using a crate is ideal, because it provides your puppy with a safe place to sleep and eat. Most pups don't want to go to the bathroom in the same place that they sleep and eat, so it also helps you with your house training goals.`,
            question_key: 'crate_style',
            answers: [
                { text: 'Crate', value: 'Crate' },
                { text: 'No Crate', value: 'NoCrate' },
            ],
        },
        {
            question: 'Will you go outside or use potty pads?',
            details: `Many veterinarians recommend quarantining your puppy as much as possible until they complete a few of their vaccinations. When this is the case, you can use potty pads for a short time to postpone the need to set your puppy on the ground outdoors.`,
            question_key: 'pee_location',
            answers: [
                { text: 'Outside', value: 'Outside' },
                { text: 'Potty Pads', value: 'PottyPads' },
            ],
        },
    ],
    lesson_paths: [
        {
            title: 'House Training',
            description: 'House training is a foundational behavior that can be taught at any age – but the earlier, the better. We\'ll break down what you\'ll need know to be as successful as possible.',
            lesson_key: 'Crate_Outside',
            chapters: [
                {
                    chapter_title: 'Lesson Overview',
                    chapter_cards: [
                        // { type: 'TransitionCard', ID: 'TRN03' },
                        { type: 'ChapterIntro', ID: 'CHP01' },
                        { type: 'VideoCard', ID: 'VID01' },
                        {
                            type: 'StuffYouNeed',
                            stuffYouNeedCards: ['EQP01', 'EQP02', 'EQP03', 'EQP04', 'EQP07', 'EQP08', 'EQP09', 'EQP10', 'EQP11'],
                        },
                    ],
                },
                {
                    chapter_title: 'Preparation Lessons',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP02' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB01A', 'SKB01B', 'SKB01C', 'SKB01D', 'SKB01E'],
                        },
                        { type: 'CheckInCard', ID: 'CHK01A1' },
                    ],
                },
                {
                    chapter_title: 'The Three Rules',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP03' },
                        { type: 'VideoCard', ID: 'VID02' },
                    ],
                },
                {
                    chapter_title: 'Reward Success',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP04' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB02C', 'SKB02D', 'SKB02E',],
                        },
                    ],
                },
                {
                    chapter_title: 'Prevent Mistakes',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP05' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB03A', 'SKB03D', 'SKB03E', 'SKB03F', 'SKB03H'],
                        },
                        { type: 'CheckInCard', ID: 'CHK02A1' },
                    ],
                },
                {
                    chapter_title: 'Scheduling',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP06' },
                        { type: 'VideoCard', ID: 'VID03' },
                    ],
                },
                {
                    chapter_title: 'Daytime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP07' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB04A', 'SKB04B', 'SKB04C', 'SKB04E', 'SKB04F', 'SKB04G'],
                        },
                    ],
                },
                {
                    chapter_title: 'Nightime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP08' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB05A', 'SKB05C', 'SKB05D', 'SKB05E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Finishing',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP09' },
                        { type: 'VideoCard', ID: 'VID04' },
                        { type: 'CheckInCard', ID: 'CHK03A1' },
                    ],
                },
            ],
        },
        {
            title: 'House Training',
            description: 'House training is a foundational behavior that can be taught at any age – but the earlier, the better. We\'ll break down what you\'ll need know to be as successful as possible.',
            lesson_key: 'Crate_PottyPads',
            chapters: [
                {
                    chapter_title: 'Lesson Overview',
                    chapter_cards: [
                        // { type: 'TransitionCard', ID: 'TRN03' },
                        { type: 'ChapterIntro', ID: 'CHP01' },
                        { type: 'VideoCard', ID: 'VID01' },
                        {
                            type: 'StuffYouNeed',
                            stuffYouNeedCards: [
                                'EQP01',
                                'EQP02',
                                'EQP03',
                                'EQP04',
                                'EQP05',
                                'EQP06',
                                'EQP07',
                                'EQP08',
                                'EQP09',
                                'EQP10',
                                'EQP11',
                            ],
                        },
                    ],
                },
                {
                    chapter_title: 'Preparation Lessons',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP02' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB01A', 'SKB01B', 'SKB01C', 'SKB01D', 'SKB01E'],
                        },
                        { type: 'CheckInCard', ID: 'CHK01A2' },
                    ],
                },
                {
                    chapter_title: 'The Three Rules',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP03' },
                        { type: 'VideoCard', ID: 'VID02' },
                    ],
                },
                {
                    chapter_title: 'Reward Success',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP04' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB02A', 'SKB02B', 'SKB02E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Prevent Mistakes',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP05' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB03A', 'SKB03B', 'SKB03D', 'SKB03E', 'SKB03G', 'SKB03H'],
                        },
                        { type: 'CheckInCard', ID: 'CHK02A2' },
                    ],
                },
                {
                    chapter_title: 'Scheduling',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP06' },
                        { type: 'VideoCard', ID: 'VID03' },
                    ],
                },
                {
                    chapter_title: 'Daytime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP07' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB04A', 'SKB04B', 'SKB04C', 'SKB04E', 'SKB04F', 'SKB04G'],
                        },
                    ],
                },
                {
                    chapter_title: 'Nightime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP08' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB05A', 'SKB05C', 'SKB05D', 'SKB05E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Transition to Outside',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP09B' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB06A', 'SKB06C', 'SKB06D', 'SKB06E', 'SKB06F'],
                        },
                    ],
                },
                {
                    chapter_title: 'Finishing',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP10' },
                        { type: 'VideoCard', ID: 'VID04' },
                        { type: 'CheckInCard', ID: 'CHK03A2' },
                    ],
                },
            ],
        },
        {
            title: 'House Training',
            description: 'House training is a foundational behavior that can be taught at any age – but the earlier, the better. We\'ll break down what you\'ll need know to be as successful as possible.',
            lesson_key: 'NoCrate_Outside',
            chapters: [
                {
                    chapter_title: 'Lesson Overview',
                    chapter_cards: [
                        // { type: 'TransitionCard', ID: 'TRN03' },
                        { type: 'ChapterIntro', ID: 'CHP01' },
                        { type: 'VideoCard', ID: 'VID01' },
                        {
                            type: 'StuffYouNeed',
                            stuffYouNeedCards: [
                                'EQP02',
                                'EQP03',
                                'EQP04',
                                'EQP05',
                                'EQP06',
                                'EQP07',
                                'EQP08',
                                'EQP09',
                                'EQP10',
                                'EQP11',
                            ],
                        },
                    ],
                },
                {
                    chapter_title: 'Preparation Lessons',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP02' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB01B', 'SKB01C', 'SKB01D', 'SKB01E'],
                        },
                        { type: 'CheckInCard', ID: 'CHK01B1' },
                    ],
                },
                {
                    chapter_title: 'The Three Rules',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP03' },
                        { type: 'VideoCard', ID: 'VID02' },
                    ],
                },
                {
                    chapter_title: 'Reward Success',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP04' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB02C', 'SKB02D', 'SKB02E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Prevent Mistakes',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP05' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB03C', 'SKB03D', 'SKB03E', 'SKB03F', 'SKB03H'],
                        },
                        { type: 'CheckInCard', ID: 'CHK02B1' },
                    ],
                },
                {
                    chapter_title: 'Scheduling',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP06' },
                        { type: 'VideoCard', ID: 'VID03' },
                    ],
                },
                {
                    chapter_title: 'Daytime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP07' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB04A', 'SKB04B', 'SKB04D', 'SKB04E', 'SKB04F', 'SKB04G'],
                        },
                    ],
                },
                {
                    chapter_title: 'Nightime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP08' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB05B', 'SKB05C', 'SKB05D', 'SKB05E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Finishing',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP09' },
                        { type: 'VideoCard', ID: 'VID04' },
                        { type: 'CheckInCard', ID: 'CHK03B1' },
                    ],
                },
            ],
        },
        {
            title: 'House Training',
            description: 'House training is a foundational behavior that can be taught at any age – but the earlier, the better. We\'ll break down what you\'ll need know to be as successful as possible.',
            lesson_key: 'NoCrate_PottyPads',
            chapters: [
                {
                    chapter_title: 'Lesson Overview',
                    chapter_cards: [
                        // { type: 'TransitionCard', ID: 'TRN03' },
                        { type: 'ChapterIntro', ID: 'CHP01' },
                        { type: 'VideoCard', ID: 'VID01' },
                        {
                            type: 'StuffYouNeed',
                            stuffYouNeedCards: [
                                'EQP02',
                                'EQP03',
                                'EQP04',
                                'EQP05',
                                'EQP06',
                                'EQP07',
                                'EQP08',
                                'EQP09',
                                'EQP10',
                                'EQP11',
                            ],
                        },
                    ],
                },
                {
                    chapter_title: 'Preparation Lessons',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP02' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB01A', 'SKB01B', 'SKB01C', 'SKB01D', 'SKB01E'],
                        },
                        { type: 'CheckInCard', ID: 'CHK01B2' },
                    ],
                },
                {
                    chapter_title: 'The Three Rules',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP03' },
                        { type: 'VideoCard', ID: 'VID02' },
                    ],
                },
                {
                    chapter_title: 'Reward Success',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP04' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB02A', 'SKB02B', 'SKB02E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Prevent Mistakes',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP05' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB03C', 'SKB03D', 'SKB03E', 'SKB03G', 'SKB03H'],
                        },
                        { type: 'CheckInCard', ID: 'CHK02B2' },
                    ],
                },
                {
                    chapter_title: 'Scheduling',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP06' },
                        { type: 'VideoCard', ID: 'VID03' },
                    ],
                },
                {
                    chapter_title: 'Daytime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP07' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB04A', 'SKB04B', 'SKB04D', 'SKB04E', 'SKB04F', 'SKB04G'],
                        },
                    ],
                },
                {
                    chapter_title: 'Nightime',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP08' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB05B', 'SKB05C', 'SKB05D', 'SKB05E'],
                        },
                    ],
                },
                {
                    chapter_title: 'Transition to Outside',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP09B' },
                        {
                            type: 'SkillBuilder',
                            skillBuilderCards: ['SKB06B', 'SKB06C', 'SKB06D', 'SKB06E', 'SKB06F'],
                        },
                    ],
                },
                {
                    chapter_title: 'Finishing',
                    chapter_cards: [
                        { type: 'ChapterIntro', ID: 'CHP10' },
                        { type: 'VideoCard', ID: 'VID04' },
                        { type: 'CheckInCard', ID: 'CHK03B2' },
                    ],
                },
            ],
        },
    ],
}

function Lesson() {
    return lesson
}

export default Lesson()
