import React from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import ExpandMoreIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { getCardByID } from '../../courses_config/data'

export default function MyAccordion({ step }) {
    const [isExpanded, setExpanded] = React.useState(false)
    const card = getCardByID(step)

    const openLink = (e) => {
        e.stopPropagation()
        window.open(card?.link, "_blank")
    }

    return (
    <Box elevation={0} onClick={()=>setExpanded(prev=>!prev)}
      sx={{ border: '0px solid #fff' }} style={{ marginBottom: 12 }}>
        <Box
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <div style={{display: "flex", width: "100%"}}>
                <img
                    alt={"dog img"}
                    src={card.image}
                    style={{
                        marginRight:"20px",
                        height: '60px',
                        width: '60px',
                        objectFit: "contain"
                    }}
                />
                <Box style={{ width: "100%", alignItems:"center" }}>
                    <Box style={{ display: "flex", width: "100%", height: '60px', alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{fontSize: "14px", color: "#392D2C", textAlign: "left", fontWeight: 700}}>{card?.name || ""}</div>
                        <SvgIcon
                            sx={{ width: 20, height: 20, path: { fill: "#392D2C" } }}
                            component={isExpanded ? RemoveIcon : ExpandMoreIcon}
                            viewBox="0 0 20 20"
                        />
                    </Box>
                    {
                        isExpanded ? 
                            <Box style={{ marginTop: 10 }}>
                                <div style={{ textAlign: "left", marginTop: 10, marginBottom: 10 }}>
                                    {card?.description || ""}
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontSize: "14px", color: "#EFA329"}}>${card?.price || "0"}</div>
                                    <div style={{fontSize: "14px", fontWeight: 700, color: "#EFA329"}} onClick={openLink}>Buy Now on Amazon</div>
                                </div>
                            </Box>
                        :
                            null    
                    }
                </Box>
            </div>
        </Box>
    </Box>
    )
}
