import { getCourses } from '../courses_config/data.js'
import { Link } from 'react-router-dom'
let courses = getCourses()

export default function Courses() {
  return (
    <div style={{ padding: '1rem 0' }}>
      <h2>Courses</h2>
      {courses.map((course) => (
        <Link to={`/course/${course.id}/lessons`} key={course.id} data-ms-content={'courses'}>
          {course.name}
        </Link>
      ))}

      <a
        data-ms-membership={'61ac2fd1b0e3070004cc3215'}
        href="http://localhost:3000/courses#/ms/signup/61ac2fd1b0e3070004cc3215"
      >
        Log-in
      </a>
    </div>
  )
}
