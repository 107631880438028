import * as React from 'react'
import Container from '@mui/material/Container'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stories from 'react-insta-stories'

import { getCardByID } from '../../courses_config/data.js'

import Typography from '@mui/material/Typography'

import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import { ReactComponent as TOCIcon } from '../../assets/toc_icon.svg'
import SvgIcon from '@mui/material/SvgIcon'

import CustomCard from './CustomCard.js'
import { useLocation } from 'react-router-dom'
import { DESKTOP_WIDTH } from '../constants.js'

export default function SkillBuilder({ skillBuilderCards, next, prev, setCurrentCard }) {
  const location = useLocation()

  var steps = skillBuilderCards
  const maxSteps = steps.length

  const currentlyPlayingDuration = (duration) => {
    return duration
  }

  const generateStories = () => {
    var theStories = steps.map((step, index) => {
      const card = getCardByID(step)
      let duration = 30;

      return {
        duration: duration * 1000,
        content: ({ story, action, isPaused, config, messageHandler }) => {
          return (
            <CustomCard
              maxSteps={maxSteps}
              card={card}
              step={step}
              index={index}
              setCurrentCard={setCurrentCard}
              next={next}
              action={action}
              isPaused={isPaused}
              config={config}
              messageHandler={messageHandler}
              currentlyPlayingDuration={currentlyPlayingDuration}
            />
          )
        },
      }
    })
    return theStories
  }

  return (
    <Box
      className={'notch-space real-full-heightss'}
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: DESKTOP_WIDTH,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'black',
        overflow: 'visible',

      }}
    >
      <Grid container alignItems="top">
        <Grid item sx={{ padding: '24px', zIndex: 1000 }} xs={6} justifyContent="center" alignItems="center">
          <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(prev)}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 700,
                fontFamily: 'serif',
                color: 'white',
                textDecoration: 'none',
              }}
              align="left"
              variant="h5"
              gutterBottom
              component="div"
            >
              <SvgIcon
                sx={{ width: 24, height: 16, path: { fill: 'white' } }}
                component={BackIcon}
                viewBox="0 0 24 17"
              />
            </Typography>
          </Box>
        </Grid>
        {
          location.pathname === "/onboarding" ? null :
            <Grid
              item
              xs={6}
              justifyContent="end"
              alignItems="end"
              sx={{ textAlign: 'right', padding: '24px', zIndex: 1000 }}
            >
              <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(-2)}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                    fontFamily: 'buena-park-jf',
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  align="right"
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  <SvgIcon
                    sx={{ width: 17, height: 21, path: { fill: 'white' } }}
                    component={TOCIcon}
                    viewBox="0 0 17 21"
                  />
                </Typography>
              </Box>
            </Grid>
        }
      </Grid>

      <Container
        disableGutters={true}
        maxWidth="lg"
        sx={{
          position: 'absolute',
          top: 0,
          // top: 'calc(env(safe-area-inset-top))',
          // top: 'calc(-25px + env(safe-area-inset-top))',
          backgroundColor: 'white',
          height: '100vh',
          zIndex: 99,
          overflow: 'visible',
        }}
      >
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} id="stories-component">
            <Stories stories={generateStories()} width={'100%'} height={'100vh'} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
