import * as React from 'react'
import Typography from '@mui/material/Typography'

import { getLessons, getCourse } from '../courses_config/data.js'
import { Link, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

export default function Lessons() {
  const [progress] = React.useState(50)

  let params = useParams()
  let lessons = getLessons(params.courseId)
  let course = getCourse(params.courseId)

  return (
    <Grid container sx={{ backgroundColor: 'rgba(228,219,198,1)' }}>
      <Grid item xs={12}>
        <Box sx={{ position: 'relative', backgroundColor: 'black' }}>
          <Grid container xs={12} sx={{ position: 'relative', zIndex: 1 }}>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{ padding: '24px', zIndex: 1, borderBottom: '1px solid rgba(228,219,198,.35)' }}
            >
              <Typography
                sx={{
                  color: 'rgba(228,219,198,1)',
                  fontSize: 18,
                  fontWeight: 700,
                  fontFamily: 'serif',
                }}
                align="center"
                variant="h5"
                gutterBottom
                component="div"
              >
                My Training Plan
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '48px 12px 48px 12px', zIndex: 1 }}>
              <Typography
                sx={{ color: 'rgba(228,219,198,1)', fontWeight: 700 }}
                align="center"
                variant="p"
                gutterBottom
                component="div"
              >
                ENROLLED COURSE
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(228,219,198,1)',
                  fontWeight: 700,
                  fontSize: 48,
                  fontFamily: 'serif',
                }}
                align="center"
                variant="h5"
                gutterBottom
                component="div"
              >
                {course.name}
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignItems="center" sx={{ padding: '12px 12px 12px 12px' }}>
              <LinearProgress
                sx={{
                  height: 18,
                  borderRadius: 4,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'rgba(255,255,255,.25)',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 4,
                    backgroundColor: 'rgba(228,219,198,1)',
                  },
                }}
                variant="determinate"
                value={progress}
              />
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{ padding: '24px 12px', textAlign: 'center' }}
            >
              <Button
                sx={{
                  backgroundColor: '#f46e4a',
                  borderRadius: 0,
                  fontFamily: 'basic-sans, sans-serif',
                  color: 'rgba(47,38,37,1)',
                  fontSize: 14,
                  padding: '12px 48px',
                  fontWeight: 700,
                  boxShadow: '8px 8px 0px rgba(47,38,37,1)',
                  '&:hover': {
                    backgroundColor: '#F46E4A',
                  },
                }}
                disableRipple
                variant="contained"
              >
                Start or Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <img
        alt="the dog"
        src="https://placedog.net/700/1200"
        width={'100%'}
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 0,
          objectFit: 'cover',
          opacity: 0.4,
          overflow: 'hidden',
        }}
      />

      <Grid item xs={12} sx={{ zIndex: 1, padding: '24px' }}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: 'rgba(228,219,198,1)',
            border: '3px solid rgba(38,60,19,1)',
            borderRadius: 0,
            padding: '24px',
          }}
        >
          <Typography
            sx={{ color: 'rgba(47,38,37,1)', fontWeight: 700, fontSize: 28, fontFamily: 'serif' }}
            align="left"
            variant="h5"
            gutterBottom
            component="div"
          >
            Lessons
          </Typography>
          <Grid container>
            {lessons.map((lesson) => (
              <Grid item xs={12} sx={{ padding: '12px 0 12px 0' }}>
                <Link
                  to={`/course/${params.courseId}/lessons/${lesson.id}`}
                  key={lesson.id}
                  style={{ textDecoration: 'none' }}
                >
                  <Grid container align="center">
                    <Grid item xs={4} align="start">
                      <img alt="the dog" src="https://placedog.net/75/50" />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: 'rgba(47,38,37,1)',
                          fontWeight: 600,
                          fontSize: 16,
                          fontFamily: 'serif',
                          textDecoration: 'none',
                        }}
                        variant="h5"
                        gutterBottom
                        component="div"
                      >
                        {lesson.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
