import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import SvgIcon from '@mui/material/SvgIcon'
import { DESKTOP_WIDTH } from '../constants'
import Typography from '@mui/material/Typography'
import { swipeHandler } from '../../components/ui/utils.js'
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import { ReactComponent as TOCIcon } from '../../assets/toc_icon.svg'
import { ReactComponent as InfoIcon } from '../../assets/info_icon.svg'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'


export default function VideoCard({ ID, headline, body, video_url, thumbnail, button_text, next, prev, setCurrentCard }) {
  const ls_video_watched = localStorage.getItem(`video_watched_${ID}`)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [showContinue, setShowContinue] = React.useState(ls_video_watched ? JSON.parse(ls_video_watched) : false)
  const [isInfo, setIsInfo] = React.useState(false)
  const [customClass, setCustomClass] = React.useState("")
  const vidRef = React.useRef(null)

  const togglePlayback = (e) => {
    e.preventDefault()
    if (vidRef.current.paused) {
      vidRef.current.play()
      setIsPlaying(true)
    } else {
      vidRef.current.pause()
      setIsPlaying(false)
    }
  }

  const [videoCurrentProgress, setVideoCurrentProgress] = React.useState(0)

  const onVideoEnded = (e) => {
    localStorage.setItem(`video_watched_${ID}`, true)
    setShowContinue(true)
  }

  const showCurrentTime = (videoDom) => {
    setVideoCurrentProgress((videoDom?.currentTime / videoDom?.duration) * 100
    );
  }

  React.useEffect(() => {
    vidRef.current.addEventListener("timeupdate", () => {
      showCurrentTime(vidRef.current);
    });
  }, []);

  React.useEffect(() => {
    let element = document.getElementById("SwipableDIV");

    swipeHandler(element, function (evt, dir, phase, swipetype, distance) {
      if (phase == 'end' && (dir == 'left' || dir == 'right')) {
        console.log({ distance });
        if (dir === "left" && Math.abs(distance) > 100) {
          setCustomClass(" move-left ")
          setTimeout(() => { setCurrentCard(next) }, 100);
        }
        if (dir == 'right' && Math.abs(distance) > 100) {
          setCustomClass(" move-right ")
          setTimeout(() => { setCurrentCard(prev) }, 100);
        }
      }
    })
  }, [])

  return (
    <Box
      className={'notch-space real-full-height' + customClass}
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: DESKTOP_WIDTH,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'white',
      }}
    >
      <progress id="progress_bar" value={vidRef.current?.currentTime} max={vidRef.current?.duration} style={{
        width: "100%",
        position: "relative",
        zIndex: 1000000000,
      }} />
      <Grid container alignItems="top">

        <Grid item sx={{ padding: '10px 24px', zIndex: 10000 }} xs={6} justifyContent="center" alignItems="center">
          <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(prev)}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 700,
                fontFamily: 'buena-park-jf',
                color: 'white',
                textDecoration: 'none',
              }}
              align="left"
              variant="h5"
              gutterBottom
              component="div"
            >
              <SvgIcon
                sx={{ width: 24, height: 16, path: { fill: 'white' } }}
                component={BackIcon}
                viewBox="0 0 24 17"
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} justifyContent="end" alignItems="end" sx={{ textAlign: 'right', padding: '10px 24px', zIndex: 10000 }}>
          <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(-2)}>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 700,
                fontFamily: 'buena-park-jf',
                color: 'white',
                textDecoration: 'none',
              }}
              align="right"
              variant="h5"
              gutterBottom
              component="div"
            >
              <SvgIcon
                sx={{ width: 17, height: 21, path: { fill: 'white' } }}
                component={TOCIcon}
                viewBox="0 0 17 21"
              />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ width: "100%", zIndex: 1, padding: '24px', position: 'absolute', bottom: 40 }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box style={{ display: "flex", justifyContent: isInfo ? "flex-end" : "flex-start", alignItems: "center", marginBottom: 10 }}>
              {
                isInfo ?
                  <SvgIcon
                    sx={{ height: 20, width: 20, path: { fill: 'transparent' } }}
                    component={CloseIcon}
                    viewBox="0 0 20 20"
                    onClick={() => setIsInfo(false)}
                  />
                  :
                  <SvgIcon
                    sx={{ path: { fill: 'transparent' } }}
                    component={InfoIcon}
                    viewBox="0 0 30 30"
                    onClick={() => setIsInfo(true)}
                  />
              }
            </Box>

            {
              isInfo ?
                <>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: 24,
                      fontWeight: 300,
                      fontFamily: 'buena-park-jf',
                    }}
                    align="left"
                    variant="p"
                    gutterBottom
                    component="div"
                  >
                    {headline}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontWeight: 300,
                      fontSize: 14,
                      fontFamily: 'basic-sans',
                    }}
                    align="left"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    {body}
                  </Typography>
                </>
                :
                null
            }

            {showContinue && (
              <Button
                onClick={() => setCurrentCard(next)}
                className="bottom-notch-space"
                sx={{
                  width: '100%',
                  backgroundColor: '#f46e4a',
                  borderRadius: 0,
                  fontFamily: 'basic-sans, sans-serif',
                  color: '#392D2C',
                  fontSize: 14,
                  padding: '12px 48px',
                  fontWeight: 700,
                  boxShadow: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: "10px",
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                  '&:hover': {
                    backgroundColor: '#F46E4A',
                  },
                }}
                disableRipple
                variant="contained"
              >
                {button_text}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        style={{
          top: 0,
          left: 0,
          zIndex: 100,
          width: "100%",
          height: "20vh",
          position: 'absolute',
          background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%)',
        }}
      />

      <Box id="SwipableDIV" style={{ objectFit: 'cover', position: 'absolute', height: '100%', width: '100%', top: '0' }}>
        <div
          onClick={togglePlayback}
          style={{
            position: 'relative',
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            backgroundColor: 'black',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              zIndex: 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: 0,
              background: 'transparent',
              boxSizing: 'border-box',
              width: 0,
              height: '100px',
              borderColor: 'transparent transparent transparent #d6d6d644',
              borderStyle: 'solid',
              borderWidth: '50px 0 50px 75px',
              opacity: isPlaying ? 0 : 1,
              transition: 'opacity 0.3s,',
            }}
          />

          <video
            style={{
              position: 'absolute',
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100vh',
              left: 0,
              opacity: 1,
            }}
            ref={vidRef}
            muted={false}
            autoPlay={false}
            playsInline
            poster={thumbnail}
            onEnded={onVideoEnded}
          >
            <source src={`${video_url}`} type="video/mp4" />
          </video>
        </div>
      </Box>
    </Box>
  )
}
