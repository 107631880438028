let skillbuilders = [
  {
    "Page": "Skillbuilder 00: Touch",
    "ID": "SKB00A",
    "headline": "Cue and Lure",
    "body": "With an open palm, place a treat in your hand and hold it there with your thumb. Hold your palm open, like a target, and say \"touch\" one time.  ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB00A_CueandLure.mp4",
    "duration": 5.46,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB00A.mp3",
    "Notes": "With an open palm, place a treat in your hand and hold it there with your thumb. Hold your palm open, like a target, and say \"touch\" one time. "
  },
  {
    "Page": "Skillbuilder 00: Touch",
    "ID": "SKB00B",
    "headline": "Mark",
    "body": "When your dog's nose touches your palm, click or say \"good\" to mark the correct behavior.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB00B_Mark.mp4",
    "duration": 2.58,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB00B.mp3",
    "Notes": "When your dog's nose touches your palm, click or say \"good\" to mark the correct behavior."
  },
  {
    "Page": "Skillbuilder 00: Touch",
    "ID": "SKB00C",
    "headline": "Reward",
    "body": "Right after you mark (or click), give your dog the treat.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB00C_Reward.mp4",
    "duration": 3.75,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB00C.mp3",
    "Notes": "Right after you mark (or click), give your dog the treat."
  },
  {
    "Page": "Skillbuilder 00: Touch",
    "ID": "SKB00D",
    "headline": "Remove Lure",
    "body": "Once your dog has done a \"touch\" with the treat in your hand a few times, remove the treat lure.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB00D_RemoveLure.mp4",
    "duration": 8.96,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB00D.mp3",
    "Notes": "Once your dog has done a \"touch\" with the treat in your hand a few times, remove the treat lure."
  },
  {
    "Page": "Skillbuilder 00: Touch",
    "ID": "SKB00E",
    "headline": "Success",
    "body": "Now that you know the steps, put it all together!",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB00E_Success.mp4",
    "duration": 5.58,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB00E.mp3",
    "Notes": "Now that you know the steps, put it all together!"
  },
  {
    "Page": "Skillbuilder 00: Touch",
    "ID": "SKB00F",
    "headline": "Finish",
    "body": "Congratulations! You just taught your dog to \"touch\" on cue! From here, practice touch for a few minutes twice each day in a variety of places. Your dog will be a touch pro by the end of the week.",
    "button_text": "COMPLETE WARMUP",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB00F_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB00F.mp3",
    "Notes": "Congratulations! You just taught your dog to \"touch\" on cue!"
  },
  {
    "Page": "Skillbuilder 01: Preparation Lessons",
    "ID": "SKB01A",
    "headline": "Setting Up Your Crate",
    "body": "Set up your crate within your exercise pen. If you are using a potty pad, place it as far as possible from your crate, within the pen. ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB01A_Crate.mp4",
    "duration": 6.04,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB01A.mp3",
    "Notes": "It's best for your puppy to sleep in a crate. "
  },
  {
    "Page": "Skillbuilder 01: Preparation Lessons",
    "ID": "SKB01B",
    "headline": "Stuffing a Kong",
    "body": "It's best for young dogs to eat all of their food from puzzle toys, like kongs. To do this, stuff your dog's food into several kongs and keep them in the fridge. You can give them to your dog at meal times. If your dog eats dry kibble, you can soften it by running some water over it before stuffing it ito the kong.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB01B_KongStuffing.mp4",
    "duration": 8.17,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB01B.mp3",
    "Notes": "It's best for young dogs to eat all of their food from puzzle toys, like kongs. To do this, stuff your dog's food into several kongs and keep them in the fridge. You can give them to your dog at meal times. If your dog eats dry kibble, you can soften it by running some water over it before stuffing it ito the kong."
  },
  {
    "Page": "Skillbuilder 01: Preparation Lessons",
    "ID": "SKB01C",
    "headline": "Water",
    "body": "It's best to give your puppy water on a schedule. Let them drink as much water as they want to, but do it at designated times or when they are thirsty. Don't leave it on the ground all day, or they'll need to pee all day!",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB01C_Water.mp4",
    "duration": 7.33,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB01C.mp3",
    "Notes": "It's best to give puppies water on a schedule. Let them drink as much water as they want to, but do it at designated times or when they are thirsty. Don't leave it on the ground all day, or they'll need to pee all day."
  },
  {
    "Page": "Skillbuilder 01: Preparation Lessons",
    "ID": "SKB01D",
    "headline": "Cleaning Up Accidents",
    "body": "Many cleaning products can't get rid of the smell of dog pee completely. Since puppies often like to pee where it smells like pee, it's essential that you use an enzymatic cleaner (such as Nature's Miracle) and let it soak in completely before wiping up the puddle.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB01D_Accidents.mp4",
    "duration": 12.96,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB01D.mp3",
    "Notes": "Many cleaning products can't get rid of the smell of dog pee completely. Since puppies often like to pee where it smells like pee, it's essential that you use an enzymatic cleaner (such as nature's miracle) and let it soak in completely before wiping up the puddle."
  },
  {
    "Page": "Skillbuilder 01: Preparation Lessons",
    "ID": "SKB01E",
    "headline": "Finishing",
    "body": "You're ready for action! Let's start teaching your puppy when and where to go potty.",
    "button_text": "CONTINUE",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB01E_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB01E.mp3",
    "Notes": "You're ready for action! Let's start teaching your puppy when and where to go potty."
  },
  {
    "Page": "Skillbuilder 02: Reward Success",
    "ID": "SKB02A",
    "headline": "Potty Pad Pee",
    "body": "Pick a designated spot to place your pee pad – if they know where to find it each time, you'll be that much more successful. Wait until your puppy successfully goes on the pad.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB02A_PottyPadPee.mp4",
    "duration": 2.58,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB02A.mp3",
    "Notes": "Wait until your puppy successfully goes on the pad..."
  },
  {
    "Page": "Skillbuilder 02: Reward Success",
    "ID": "SKB02B",
    "headline": "Potty Pad Party",
    "body": "Then throw a potty party! Give a few treats, ooh and ahh, and if you're into it, a little baby talk goes a long way.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB02B_PottyPadParty.mp4",
    "duration": 6.75,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB02B.mp3",
    "Notes": "...then throw a potty party! Give a few treats, ooh and ahh, and if you're into it, a little baby talk goes a long way."
  },
  {
    "Page": "Skillbuilder 02: Reward Success",
    "ID": "SKB02C",
    "headline": "Outside Pee",
    "body": "Pick a designated spot to bring your puppy when going outside – look for grass, dirt, or a curb, as no one likes to step in a puddle on the sidewalk! Wait until your puppy goes potty in the spot.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB02C_OutsidePee.mp4",
    "duration": 5.75,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB02C.mp3",
    "Notes": "Wait until your puppy goes potty outside..."
  },
  {
    "Page": "Skillbuilder 02: Reward Success",
    "ID": "SKB02D",
    "headline": "Outside Party",
    "body": "Then throw a potty party! Give a few treats, ooh and ahh, and if you're into it, a little baby talk goes a long way.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB02D_OutsideParty.mp4",
    "duration": 7.25,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB02D.mp3",
    "Notes": "...then throw a potty party! Give a few treats, ooh and ahh, and if you're into it, a little baby talk goes a long way."
  },
  {
    "Page": "Skillbuilder 02: Reward Success",
    "ID": "SKB02E",
    "headline": "Finishing",
    "body": "Nicely done! Repeat these steps until your puppy starts to have some success going potty in the right place!",
    "button_text": "CONTINUE",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB02E_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB02E.mp3",
    "Notes": "Nice! Your puppy has started to have some success going potty in the right place!"
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03A",
    "headline": "Crate Setup",
    "body": "You puppy should sleep and eat in their crate. Since most puppies are naturally averse to going to the bathroom where they sleep and eat, they will be less likely to go when they are inside their crate. ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03A_CrateSetup.mp4",
    "duration": 7.88,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03A.mp3",
    "Notes": "A crate should be just large enough for your dog to stand up, turn around and lie back down. It's a crib, not a playroom."
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03B",
    "headline": "Pen Setup with Crate",
    "body": "A pen can be set up around the crate to give your puppy a little space to play safely outside their crib while you are unable to supervise them.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03B_PenSetupCrate.mp4",
    "duration": 5.88,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03B.mp3",
    "Notes": "A pen can be set up around the crate to give your puppy a little space to play safely outside their crib while you are unable to supervise them."
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03C",
    "headline": "Bed and Potty Pad Setup",
    "body": "Inside your pup's play pen, you should set the bed and potty pad as far apart as possible.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03C_BedPottyPad.mp4",
    "duration": 8,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03C.mp3",
    "Notes": "Inside your pup's play pen, you should set the bed and potty pad as far apart as possible."
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03D",
    "headline": "Supervising",
    "body": "During supervised time, watch your puppy closely. The moment you look away, they might have an accident. This is especially true if they have had a lot of water or have been playing vigorously.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03D_Supervising.mp4",
    "duration": 6.33,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03D.mp3",
    "Notes": "Watch your puppy viigiliantly. The moment you look away, they might have an accident. This is especially true if they have had a lot of water or have been playing vigorously."
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03E",
    "headline": "Interrupt & Redirect",
    "body": "If your puppy starts to potty in the wrong place, say \"oops,\" scoop them up facing away from you, and quickly hustle to their potty spot.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03E_Interrupt.mp4",
    "duration": 4.71,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03E.mp3",
    "Notes": "If your puppy starts to potty in the wrong place, say \"oops,\" quickly scoop them up facing away from you, and quickly hustle to their potty spot."
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03F",
    "headline": "Place Outside",
    "body": "Place your puppy down in their potty spot and give them a few minutes to go potty. If you caught them early enough, they will still need to go, and you can reward them heavily for going in the right place. ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03F_PlaceOutside.mp4",
    "duration": 5.75,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03F.mp3",
    "Notes": "Place your puppy down in their potty spot and give them a few minutes to go potty. If you caught them early enough, they will still need to go, and you can reward them heavily for going in the right place. "
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03G",
    "headline": "Place on Pad",
    "body": "Place your puppy down on their potty pad and give them a few minutes to go potty. If you caught them early enough, they will still need to go, and you can reward them heavily for going in the right place. ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03G_PlaceonPad.mp4",
    "duration": 7.04,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03G.mp3",
    "Notes": "Place your puppy down on their potty pad and give them a few minutes to go potty. If you caught them early enough, they will still need to go, and you can reward them heavily for going in the right place. "
  },
  {
    "Page": "Skillbuilder 03: Prevent Mistakes",
    "ID": "SKB03H",
    "headline": "Finishing",
    "body": "Great work! You're helping to prevent your puppy from making bad habits in the first place!",
    "button_text": "CONTINUE",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB03H_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB03H.mp3",
    "Notes": "Great work! You're helping to prevent your puppy from making bad habits in the first place!"
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04A",
    "headline": "Block 1: Free Time",
    "body": "After your puppy has a successful potty outting, start your schedule with one block of free time. During free time, you should limit your dog to a small, puppy-proofed area of your home for safety, but you can feel free to take your eyes off of them and do other things. Free time is also a great time to exercise your pup.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04A_FreeTime.mp4",
    "duration": 5.13,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04A.mp3",
    "Notes": "After your puppy has a successful potty outting, start your schedule with one block of free time. During free time, you should limit your dog to a small, puppy-proofed area of your home for safety, but you can feel free to take your eyes off of them and do other things. Free time is also a great time to exercise your pup."
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04B",
    "headline": "Block 2: Supervised Time",
    "body": "After a block of free time, your puppy is more likely to need to potty, so begin a block of supervised time.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04B_SupervisedTime.mp4",
    "duration": 8.96,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04B.mp3",
    "Notes": "After a block of free time, your puppy is more likely to need to potty, so begin a block of supervised time. (CAN WE LINK HERE TO HOW TO SUPERVISE, REMINDER?)"
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04C",
    "headline": "Block 3: Nap Time",
    "body": "After a block of supervised time, place a food stuffed kong in the crate, and place your puppy inside. It's time for a snack and a nap!",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04C_NapTimeCrate.mp4",
    "duration": 11.67,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04C.mp3",
    "Notes": "After a block of supervised time, place a food stuffed kong in the crate, and place your puppy inside. It is time for a snack and a nap."
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04D",
    "headline": "Block 3: Nap Time",
    "body": "After a block of supervised time, offer your puppy plenty of water, then take the water bowl away, then place a food stuffed kong on your puppy's bed. It is time for a snack and a nap!",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04D_NapTimeBed.mp4",
    "duration": 7.88,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04D.mp3",
    "Notes": "After a block of supervised time, offer your puppy plenty of water, take the water bowl away, then place a food stuffed kong on your puppy's bed. It is time for a snack and a nap."
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04E",
    "headline": "Establish a Routine",
    "body": "Repeat the three block schedule throughout your day. Once this routine is established, your puppy will start understanding when and where to go, and you will have fewer accidents each day. Stick to this routine until your puppy is accident-free for fifteen days.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04E_EstablishRoutine.mp4",
    "duration": 6,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04E.mp3"
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04F",
    "headline": "Daytime Schedule Overview",
    "body": "After fifteen days with no accidents, you can start expanding the amount of time in each block. Add fifteen minutes to each block at first. If your puppy does well for a couple of days, add fifteen more. By the time your puppy is about four months old, they can likely hold it for about five hours. ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04F_DTScheduleGraphic.mp4",
    "duration": 9.71,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04F.mp3",
    "Notes": "After fifteen days with no accidents, you can start expanding the amount of time in each block. Add fifteen minutes to each block at first. If your puppy does well for a couple of days, add fifteen more. By the time your puppy is about four months old, they can likely hold it for about five hours. "
  },
  {
    "Page": "Skillbuilder 04: Daytime Scheduling",
    "ID": "SKB04G",
    "headline": "Finishing",
    "body": "Rinse and repeat! Keep to a schedule and your puppy's bathroom habits will become a routine you can predict.",
    "button_text": "CONTINUE",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB04G_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB04G.mp3",
    "Notes": "Rinse and repeat! Keep to a predictable schedule and your puppy's bathroom habites will become a routine your can predict."
  },
  {
    "Page": "Skillbuilder 05: Nightime Scheduling",
    "ID": "SKB05A",
    "headline": "Nighttime Wake Up",
    "body": "Once, in the middle of the night, scoop up your puppy and take them directly to their potty spot. Wait for a potty and reward.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB05A_WakeupNightCrate.mp4",
    "duration": 4.96,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB05A.mp3",
    "Notes": "Once, in the middle of the night, scoop up your puppy and take them directly to their potty spot.  Wait for a potty and reward."
  },
  {
    "Page": "Skillbuilder 05: Nightime Scheduling",
    "ID": "SKB05B",
    "headline": "Nighttime Wake Up",
    "body": "Once, in the middle of the night, scoop up your puppy and take them directly to their potty spot. Wait for a potty and reward.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB05B_WakeupNightBed.mp4",
    "duration": 5.67,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB05B.mp3",
    "Notes": "Once, in the middle of the night, scoop up your puppy and take them directly to their potty spot. Wait for a potty and reward."
  },
  {
    "Page": "Skillbuilder 05: Nightime Scheduling",
    "ID": "SKB05C",
    "headline": "Establish a Routine",
    "body": "Each night, take your puppy out at the same time, until they have established a routine of going to the bathroom at that time. This usually takes about 3 to 5 nights. ",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB05C_Establish+Routine.mp4",
    "duration": 4.7,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB05C.mp3"
  },
  {
    "Page": "Skillbuilder 05: Nightime Scheduling",
    "ID": "SKB05D",
    "headline": "Nighttime Schedule Overview",
    "body": "Once your puppy is used to the midnight outting, you can start taking them out a little bit later each night until they are sleeping through the night. If they have an accident, they probably can't hold it that long yet, and you should push the time back a bit more slowly.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB05D_NTScheduleGraphic.mp4",
    "duration": 8.54,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB05D.mp3",
    "Notes": "Once your puppy is used to the midnight outting, you can start taking them out a little bit later each night until they are sleeping through the night. If they have an accident, they probably can't hold it that long yet, and you should push the time back a bit more slowly."
  },
  {
    "Page": "Skillbuilder 05: Nightime Scheduling",
    "ID": "SKB05E",
    "headline": "Finishing",
    "body": "Congrats! Once you complete these steps, you will be right back to sleeping through the night. ",
    "button_text": "CONTINUE",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB05E_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB05E.mp3",
    "Notes": "Congrats! You can now sleep through the night!"
  },
  {
    "Page": "Skillbuilder 06: Transition to Outside",
    "ID": "SKB06A",
    "headline": "Morning Wake Up",
    "body": "First thing in the morning, take your puppy directly to their new potty spot. Wait for a potty and then reward.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB06A_WakeUpMorningCrate.mp4",
    "duration": 8.79,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB06A.mp3",
    "Notes": "First thing in the morning, take your puppy directly to their new potty spot. Wait for a potty and reward."
  },
  {
    "Page": "Skillbuilder 06: Transition to Outside",
    "ID": "SKB06B",
    "headline": "Morning Wake Up",
    "body": "First thing in the morning, take your puppy directly to their new potty spot. Wait for a potty and then reward.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB06B_WakeUpMorningBed.mp4",
    "duration": 6.13,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB06B.mp3",
    "Notes": "First thing in the morning, take your puppy directly to their new potty spot. Wait for a potty and reward."
  },
  {
    "Page": "Skillbuilder 06: Transition to Outside",
    "ID": "SKB06C",
    "headline": "Moving Potty Pad Outside",
    "body": "If your puppy is hesitant to go on the new outdoor surface, bring their pad outside first thing in the morning, and have them try going on the pad.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB06C_BringPadOutside.mp4",
    "duration": 6.96,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB06C.mp3",
    "Notes": "If your puppy is hesitant to go on the new outdoor surface, bring their pad outside first thing in the morning, and have them try going on the pad."
  },
  {
    "Page": "Skillbuilder 06: Transition to Outside",
    "ID": "SKB06D",
    "headline": "Folding Potty Pad",
    "body": "Once your puppy has gone on the full pad a few times, fold it in half for a few outtings. Then, fold it into a quarter pad. Reward heavily for successfully using the sidewalk or grass.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB06D_FoldPad.mp4",
    "duration": 7.67,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB06D.mp3",
    "Notes": "Once your puppy has gone on the full pad a few times, fold it in half for a few outtings. Then, fold it into a quarter pad. Reward heavily for successfully using the sidewalk or grass."
  },
  {
    "Page": "Skillbuilder 06: Transition to Outside",
    "ID": "SKB06E",
    "headline": "Roll Up Your Rugs!",
    "body": "It's best to roll up your rugs when you first remove potty pads, because rugs are the most similar substrate in your home and your puppy is more likely to go there.",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB06E_RollUpRugs.mp4",
    "duration": 8.25,
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB06E.mp3",
    "Notes": "It's best to roll up your rugs when you first remove potty pads, because rugs are the most similar substrate in your home and your puppy is more likely to go there."
  },
  {
    "Page": "Skillbuilder 06: Transition to Outside",
    "ID": "SKB06F",
    "headline": "Finishing",
    "body": "Tada! Your puppy is peeing outside. Congratulations! ",
    "button_text": "CONTINUE",
    "assetUrl": "https://d136ds05yiqqh9.cloudfront.net/skillbuilders/SKB06F_Finish.png",
    "audio": "https://d136ds05yiqqh9.cloudfront.net/audio/SKB06F.mp3",
    "Notes": "Tada! Your puppy is peeing outside. Congratulations! "
  }
]

function Skillbuilders() {
  return skillbuilders
}

export default Skillbuilders()
