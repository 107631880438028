import * as React from 'react'
import { getCardByID } from '../courses_config/data.js'
import CheckInCard from '../components/chapter_templates/CheckinCard.js'
import FinalCheckInCard from '../components/chapter_templates/FinalCheckInCard.js'
import TransitionCard from '../components/chapter_templates/TransitionCard'
import StuffYouNeed from '../components/chapter_templates/StuffYouNeed'
import SkillBuilder from '../components/chapter_templates/SkillBuilder'
import VideoCard from '../components/chapter_templates/VideoCard'
import OnboardingQuizCard from '../components/chapter_templates/OnboardingQuizCard'
import ChapterIntro from '../components/chapter_templates/ChapterIntro'
import { useNavigate } from 'react-router-dom'

let componentsMap = {
  CheckInCard: CheckInCard,
  FinalCheckInCard: FinalCheckInCard,
  TransitionCard: TransitionCard,
  StuffYouNeed: StuffYouNeed,
  SkillBuilder: SkillBuilder,
  VideoCard: VideoCard,
  OnboardingQuizCard: OnboardingQuizCard,
  ChapterIntro: ChapterIntro,
}

export default function Onboarding() {
  let navigate = useNavigate()
  let onboardingState = localStorage.getItem('onboarding')
  onboardingState = JSON.parse(onboardingState)
  let basicOnBoarding = localStorage.getItem("basiconboarding", "")
  const [currentCard, setCurrentCard] = React.useState(0)

  React.useEffect(() => {
    if (!window.LOGGED_IN) {
      navigate('/locked')
    }
    if (onboardingState && window.LOGGED_IN && currentCard === -1) {
      navigate('/course/puppy-kindergarden/lessons/house-training')
    } else if (basicOnBoarding === "1" && currentCard === 0) {
      navigate('/course/puppy-kindergarden/lessons/house-training')
    }
  })

  function changeCard(newCard) {
    window.scrollTo(0, 0)
    setCurrentCard(newCard)
  }

  function next() {
    if (currentCard + 1 === 4) {
      localStorage.setItem("basiconboarding", "1")
      return -1
    } else {
      return currentCard + 1
    }
  }

  function prev() {
    return currentCard - 1 <= -1 ? -1 : currentCard - 1
  }

  function card1() {
    const Component = componentsMap['TransitionCard']
    let current_card = getCardByID('TRN01')
    return <Component color="#fff" setCurrentCard={changeCard} backbutton={false} next={next()} prev={prev()} {...current_card} />
  }

  function card2() {
    const Component = componentsMap['OnboardingQuizCard']
    let quiz = [
      getCardByID('QIZ01A'),
      getCardByID('QIZ01B'),
      getCardByID('QIZ01C'),
      getCardByID('QIZ01D'),
      getCardByID('QIZ01E'),
      getCardByID('QIZ01F'),
    ]
    return <Component setCurrentCard={changeCard} next={next()} prev={prev()} quiz={quiz} />
  }

  function card3() {
    const Component = componentsMap['ChapterIntro']
    let current_card = getCardByID('CHP00')
    return <Component setCurrentCard={changeCard} next={next()} prev={prev()} {...current_card} />
  }

  function card4() {
    const Component = componentsMap['SkillBuilder']
    let skillBuilderCards = ['SKB00A', 'SKB00B', 'SKB00C', 'SKB00D', 'SKB00E', 'SKB00F']
    return <Component setCurrentCard={changeCard} next={next()} prev={prev()} skillBuilderCards={skillBuilderCards} />
  }

  return (
    <div className="onboarding-99999999">
      {currentCard === 0 && card1()}
      {currentCard === 1 && card2()}
      {currentCard === 2 && card3()}
      {currentCard === 3 && card4()}
    </div>
  )
}
