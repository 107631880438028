import { Mixpanel } from '../Mixpanel'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import PWAPrompt from 'react-ios-pwa-prompt'

export default function Home() {
    let navigate = useNavigate()
    let onboardingState = localStorage.getItem('onboarding')
    onboardingState = JSON.parse(onboardingState)

    React.useEffect(() => {
        Mixpanel.track('Viewed Locked')
        const ms_script = document.createElement('script')
        ms_script.src = 'https://api.memberstack.io/static/memberstack.js?custom'
        ms_script.setAttribute('data-memberstack-id', '141c142912516c603c747c75efec06b1')
        ms_script.onload = function () {
            window.MemberStack.onReady.then(function (member) {
                window.memberstack_member = member
                window.LOGGED_IN = member.loggedIn
                if (window.LOGGED_IN && !onboardingState) {
                    navigate('/onboarding')
                } else if (window.LOGGED_IN && onboardingState) {
                    navigate('/course/puppy-kindergarden/lessons/house-training')
                } else {
                    document.getElementById('login-link').click()
                }
            })
        }
        document.head.append(ms_script)
    }, [navigate])

    return (
        <div style={{ padding: '1rem 0' }}>
            <a id="login-link" rel="external" href="#/ms/signup/61ac2fd1b0e3070004cc3215" className="" hidden>
                <div>Get Started</div>
            </a>
            <PWAPrompt promptOnVisit={1} timesToShow={9999} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />
        </div>
    )
}
