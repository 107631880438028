let videos = [
  {
    Page: 'Video 01: Intro',
    ID: 'VID01',
    headline: 'Welcome',
    body: 'Hi there! Welcome to Gentle Beast. Meet your new instructor.',
    button_text: 'CONTINUE',
    video_url: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID01_Welcome.mp4',
    thumbnail: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID01_thumb.png',
  },
  {
    Page: 'Video 02: Three Rules',
    ID: 'VID02',
    headline: 'The Three Rules',
    body: 'These are the three essential rules of house-training. ',
    button_text: 'CONTINUE',
    video_url: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID02_TheThreeRules.mp4',
    thumbnail: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID02_thumb.png',
  },
  {
    Page: 'Video 03: Scheduling',
    ID: 'VID03',
    headline: 'Scheduling',
    body: 'Dogs eat, sleep, and pee on a schedule. If you dont create one for them, it can be very difficult to predict when they will need to go. Here is our tried and tested schedule. Follow this or create your own, but keep things consistent as much as possible!',
    button_text: 'CONTINUE',
    video_url: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID03_Scheduling.mp4',
    thumbnail: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID03_thumb.png',
  },
  {
    Page: 'Video 04: Finishing',
    ID: 'VID04',
    headline: 'Finishing',
    body: "Nice work so far! Now it's time to add time between outtings and work on the details.",
    button_text: 'CONTINUE',
    video_url: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID04_Finishing.mp4',
    thumbnail: 'https://d136ds05yiqqh9.cloudfront.net/videos/VID04_thumb.png',

  },
]

function Videos() {
  return videos
}

export default Videos()
