import * as React from 'react'

import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@mui/material/MobileStepper'
import { ReactComponent as Star } from '../../assets/star.svg'
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import { Themes, ThemeNames } from './themes'
import { DESKTOP_WIDTH } from '../constants'

export default function QuizPathCard({ quiz, onChange, coursePath }) {
  let theme_names = ThemeNames()
  let themes = Themes()

  const [activeStep, setActiveStep] = React.useState(0)
  const [currentTheme, setCurrentTheme] = React.useState(Math.floor(Math.random() * theme_names.length))

  React.useEffect(() => {
    setCurrentTheme(Math.floor(Math.random() * theme_names.length))
  }, [theme_names])

  const maxSteps = quiz.length

  const choiceChosen = (index, value) => {
    handleNext()
    onChange(index, value)
  }

  const handleNext = () => {
    setCurrentTheme(Math.floor(Math.random() * theme_names.length))
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handlePrev = () => {
    setCurrentTheme(Math.floor(Math.random() * theme_names.length))
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <Box
      className={'notch-space real-full-height'}
      sx={{
        backgroundImage: themes[theme_names[currentTheme]].backgroundImage,
        backgroundSize: '50%',
        backgroundRepeat: 'repeat',
        backgroundColor: themes[theme_names[currentTheme]].backgroundColor,
        height: '100%',
        width: '100%',
        position: 'fixed',
        zIndex: 100,
        maxWidth: DESKTOP_WIDTH
      }}
    >
      <Grid container sx={{ padding: '25px', zIndex: 1 }}>
        <Grid item xs={2} justifyContent="center" alignItems="center">
          {activeStep > 0 && (
            <Link style={{ textDecoration: 'none' }} onClick={handlePrev}>
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 700,
                  fontFamily: 'serif',
                  color: themes[theme_names[currentTheme]].textColor,
                  textDecoration: 'none',
                }}
                align="left"
                variant="h5"
                gutterBottom
                component="div"
              >
                <SvgIcon
                  sx={{
                    width: 24,
                    height: 16,
                    path: { fill: themes[theme_names[currentTheme]].textColor },
                  }}
                  component={BackIcon}
                  viewBox="0 0 24 17"
                />
              </Typography>
            </Link>
          )}
        </Grid>
        <Grid item xs={8} justifyContent="center" alignItems="center">
          <MobileStepper
            className={themes[theme_names[currentTheme]].dotsClass}
            steps={maxSteps}
            position="static"
            sx={{
              justifyContent: 'center',
              maxWidth: 400,
              backgroundColor: 'transparent',
            }}
            activeStep={activeStep}
          />
        </Grid>
        <Grid item xs={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              fontFamily: 'buena-park-jf',
              color: themes[theme_names[currentTheme]].textColor,
            }}
            align="center"
            variant="h5"
            gutterBottom
            component="div"
          >
            {activeStep + 1}/{maxSteps}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ zIndex: 1, marginTop: '90px' }}>
          <SwipeableViews axis={'x'} index={activeStep} disabled={true} onChangeIndex={handleStepChange}>
            {quiz.map(({ question, question_key, answers, details }, index) => {
              return (
                <Grid key={question_key} item xs={12}>
                  <Grid item xs={12} justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
                    <SvgIcon
                      sx={{
                        width: 24,
                        height: 36,
                        path: {
                          fill: "white",
                        },
                      }}
                      component={Star}
                      viewBox="0 0 24 36"
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: themes[theme_names[currentTheme]].textColor,
                      fontWeight: 300,
                      fontSize: 38,
                      fontFamily: 'buena-park-jf',
                    }}
                    align="center"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    {question}
                  </Typography>
                  {answers.map(({ text, value }, sub_index) => {
                    return (
                      <Box key={value} sx={{ padding: '10px 0 10px 0' }}>
                        <Button
                          onClick={() => choiceChosen(index, value)}
                          sx={{
                            width: '100%',
                            backgroundColor: themes[theme_names[currentTheme]].buttonBg,
                            borderRadius: 0,
                            fontFamily: 'buena-park-jf',
                            color: themes[theme_names[currentTheme]].buttonTextColor,
                            fontSize: 16,
                            padding: '12px 48px',
                            fontWeight: 300,
                            boxShadow: 'none',
                            textTransform: 'Capitalize',
                            '&:hover': {
                              backgroundColor: themes[theme_names[currentTheme]].buttonBg,
                            },
                          }}
                          variant="contained"
                        >
                          {text}
                        </Button>
                      </Box>
                    )
                  })}
                  <Typography
                    sx={{
                      mx: 'auto',
                      my: 3,
                      color: themes[theme_names[currentTheme]].textColor,
                      fontSize: 14,
                      fontFamily: 'buena-park-jf',
                    }}
                    align="center"
                    gutterBottom
                    component="div"
                  >{details}</Typography>
                </Grid>
              )
            })}
          </SwipeableViews>
        </Grid>
      </Grid>
    </Box>
  )
}
