import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import { ReactComponent as TOCIcon } from '../../assets/toc_icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import lightOrDarkImage from '@check-light-or-dark/image'
import { DESKTOP_WIDTH } from '../constants'
import { useLocation } from 'react-router-dom'

export default function ChapterIntro({ Page, image, headline, body, buttonText, next, prev, setCurrentCard }) {
  const location = useLocation()
  const [iconColor, setIconColor] = React.useState('white')
  let onboardingState = localStorage.getItem('onboarding')
  onboardingState = JSON.parse(onboardingState)

  // React.useEffect(() => {
  //   lightOrDarkImage({
  //     image: image,
  //     width: 30,
  //     height: 20,
  //     x: 5,
  //     y: 10,
  //   }).then((res) => {
  //     console.log(res)
  //     setIconColor(res === 'dark' ? 'white' : 'dark')
  //   })
  // }, [])

  return (
    <Grid
      className={'notch-space real-full-height'}
      container
      sx={{
        height: '100%',
        maxWidth: DESKTOP_WIDTH,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'white',
        overflow: 'auto',
        '-webkit-overflow-scrolling': 'touch',
      }}
    >
      <Grid item xs={6} justifyContent="center" alignItems="center" sx={{ padding: '24px', zIndex: 100 }}>
        <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(prev)}>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 700,
              fontFamily: 'buena-park-jf',
              color: iconColor,
              textDecoration: 'none',
            }}
            align="left"
            variant="h5"
            gutterBottom
            component="div"
          >
            <SvgIcon
              sx={{ width: 24, height: 16, path: { fill: iconColor } }}
              component={BackIcon}
              viewBox="0 0 24 17"
            />
          </Typography>
        </Box>
      </Grid>
      {
        location.pathname === "/onboarding" ? null :
          <Grid item xs={6} justifyContent="end" alignItems="end" sx={{ textAlign: 'right', padding: '24px', zIndex: 100 }}>
            <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(-2)}>
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 700,
                  fontFamily: 'buena-park-jf',
                  color: iconColor,
                  textDecoration: 'none',
                }}
                align="right"
                variant="h5"
                gutterBottom
                component="div"
              >
                <SvgIcon
                  sx={{ width: 17, height: 25, path: { fill: "white" } }}
                  component={TOCIcon}
                  viewBox="0 0 17 21"
                />
              </Typography>
            </Box>
          </Grid>
      }
      <img
        alt={headline}
        src={image}
        height={'auto'}
        width={'100%'}
        style={{
          maxWidth: DESKTOP_WIDTH,
          height: "50%",
          position: 'absolute',
          top: 0,
          zIndex: 0,
          opacity: 1,
          overflow: 'hidden',
          objectFit: "cover"
        }}
      />

      <Box
        style={{
          top: 0,
          left: 0,
          zIndex: 2,
          width: "100%",
          height: "20vh",
          position: 'absolute',
          background: 'rgb(0,0,0)',
          background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%)',
        }}
      />

      <Grid item xs={12} sx={{ zIndex: 1, marginTop: '60%', maxWidth: DESKTOP_WIDTH }}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: 0,
            padding: '24px',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: 'rgba(47,38,37,1)',
                  fontSize: 16,
                  fontWeight: 700,
                  fontFamily: 'basic-sans',
                  textTransform: 'uppercase',
                }}
                align="left"
                variant="p"
                gutterBottom
                component="div"
              >
                {Page}
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(47,38,37,1)',
                  fontSize: 24,
                  fontWeight: 300,
                  fontFamily: 'buena-park-jf',
                }}
                align="left"
                variant="p"
                gutterBottom
                component="div"
              >
                {headline}
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(47,38,37,1)',
                  fontWeight: 300,
                  fontSize: 14,
                  fontFamily: 'basic-sans',
                }}
                align="left"
                variant="h5"
                gutterBottom
                component="div"
              >
                {body}
              </Typography>
            </Grid>

            <Grid
              sx={{ padding: '24px 12px 48px 12px', zIndex: 1, textAlign: 'center' }}
              item
              xs={12}
              justifyContent="right"
              alignItems="end"
            >
              <Button
                onClick={() => setCurrentCard(next)}
                sx={{
                  width: '100%',
                  backgroundColor: '#f46e4a',
                  borderRadius: 0,
                  fontFamily: 'basic-sans',
                  color: '#392D2C',
                  fontSize: 14,
                  padding: '12px 48px',
                  fontWeight: 700,
                  boxShadow: 'none',
                  position: 'relative',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  maxWidth: 320,
                  // margin: '48px auto',
                  '&:hover': {
                    backgroundColor: '#F46E4A',
                  },
                }}
                disableRipple
                variant="contained"
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
