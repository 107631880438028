import green_texture from '../../assets/tex_green.png'
import brown_texture from '../../assets/tex_hound.png'
import yellow_texture from '../../assets/tex_yellow.png'

let theme_names = ['green', 'yellow', 'brown']
let themes = {
  green: {
    backgroundImage: `url(${green_texture})`,
    backgroundColor: '#2F4818',
    textColor: '#E7E1CC',
    buttonBg: '#F46E4A',
    buttonTextColor: '#392D2C',
    dotsClass: 'white',
  },
  yellow: {
    backgroundImage: `url(${yellow_texture})`,
    backgroundColor: '#EFA329',
    textColor: '#2F4818',
    buttonBg: '#2F4818',
    buttonTextColor: 'white',
    dotsClass: 'green-dots',
  },
  brown: {
    backgroundImage: `url(${brown_texture})`,
    backgroundColor: '#392D2C',
    textColor: '#E7E1CC',
    buttonBg: '#F46E4A',
    buttonTextColor: '#392D2C',
    dotsClass: 'white',
  },
}

export function ThemeNames() {
  return theme_names
}

export function Themes() {
  return themes
}
