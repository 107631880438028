export const quizzes = [
  {
    Page: 'Platform Onboarding Quiz',
    ID: 'QIZ01A',
    theme: 'yellow',
    question: "What's your first name?",
    form: 'Text Input',
    formText: 'First Name',
    formValue: 'firstName',
    button1Text: 'DONE',
  },
  {
    Page: 'Platform Onboarding Quiz',
    ID: 'QIZ01B',
    theme: 'yellow',
    question: "What's your last name?",
    form: 'Text Input',
    formText: 'Last Name',
    formValue: 'lastName',
    button1Text: 'DONE',
  },
  {
    Page: 'Platform Onboarding Quiz',
    ID: 'QIZ01C',
    theme: 'yellow',
    question: "What's your dog's name?",
    form: 'Text Input',
    formText: "Dog's Name",
    formValue: 'dogName',
    button1Text: 'DONE',
  },
  {
    Page: 'Platform Onboarding Quiz',
    ID: 'QIZ01D',
    question: "What's your dog's breed?",
    form: 'Text Input',
    formText: "Dog's Breed",
    formValue: 'dogBreed',
    button1Text: 'DONE',
  },
  {
    Page: 'Platform Onboarding Quiz',
    ID: 'QIZ01E',
    question: "What's your dog's age?",
    form: 'Text Input',
    formText: "Dog's Age",
    formValue: 'dogAge',
    button1Text: 'DONE',
  },
  {
    Page: 'Platform Onboarding Quiz',
    ID: 'QIZ01F',
    question: 'Upload a pic for your dog.',
    form: 'Upload Pic',
    formText: '-',
    formValue: 'dogPicture',
    button1Text: 'DONE',
  },
  {
    Page: 'Course Onboarding Quiz',
    ID: 'QIZ02A',
    question: 'Crate or no Crate?',
    form: 'button',
    button1Text: 'CRATE',
    button2Text: 'NO CRATE',
    button1Value: 'Crate',
    button2Value: 'NoCrate',
  },
  {
    Page: 'Course Onboarding Quiz',
    ID: 'QIZ02B',
    question: 'Potty Pads or Outside?',
    form: 'button',
    button1Text: 'POTTY PADS',
    button2Text: 'OUTSIDE',
    button1Value: 'PottyPads',
    button2Value: 'Outside',
  },
]

export default quizzes
