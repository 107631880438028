let equipment = [
  {
    "ID": "EQP01",
    "name": "Double Door Folding Crate",
    "description": "A crate should be safe, easy to clean, and sturdy. It is a crib, not a play area, so it should be just big enough for your dog to stand up, turn around, and lie back down. We like the ones with a removable tray, two doors (so you can open it from both sides) and a divider (so you can make it bigger as your puppy grows).",
    "price": 68.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP01.png",
    "link": "https://amzn.to/31o8uuw",
    "Notes": "A crate should be safe, easy to clean, and sturdy. We like the ones with a removable tray, two doors (so you can open it from both sides, and a divider (so you can make it bigger as your puppy grows)."
  },
  {
    "ID": "EQP02",
    "name": "Plush Dog Bed",
    "description": "It's best to wait to splurge on expensive bedding until after house training, just in case your puppy soils their designer bed.",
    "price": 25.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP02.png",

    "link": "https://amzn.to/3pvliJ2",
    "Notes": "It's wise to wait to splurge on expensive bedding until after house-training, just in case your puppy soils their designer mattress. Blankets are fine, or this simple sturdy matress is great."
  },
  {
    "ID": "EQP03",
    "name": "Folding Dog Exercise Pen",
    "description": "This versatile exercise pen will be used to create safe areas for your puppy to romp.",
    "price": 41.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP03.png",
    "link": "https://amzn.to/3sAy8aw",
    "Notes": "This versatile exercise pen will be used to create safe areas for your puppy to romp."
  },
  {
    "ID": "EQP04",
    "name": "Nature's Miracle 3-in-1",
    "description": "Nature's miracle spray uses an enzyme to clean up your puppy's pee, ensuring that the floor or rug where they had an accident no longer carries any trace of the smell. This is essential for house-training, because puppies like to pee on top of the smell of old pee and many household cleaners don't do the trick.",
    "price": 16.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP04.png",
    "link": "https://amzn.to/3Eox0t7",
    "Notes": "Nature's miracle spray uses an enzyme to clean up your puppy's pee, ensuring that the floor or rug where they had an accident no longer carries any trace of the smell. This is essential for house-training, because puppies like to pee on top of the smell of old pee and many household cleaners don't do the trick."
  },
  {
    "ID": "EQP05",
    "name": "Quick Absorb Potty Pads",
    "description": "If you're using potty pads to train your puppy indoors, you'll need stacks of these.",
    "price": 21.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP05.png",
    "link": "https://amzn.to/3Eqzp6p",
    "Notes": "If you're using potty pads to traing your puppy indoors, at first, you'll need stacks of these."
  },
  {
    "ID": "EQP06",
    "name": "Pawise Pee Pad Holder",
    "description": "Potty pad holders are great for reducing mess, and for puppies who start to chew up their pee pads. It clamps down around the potty pad, holding it neatly in place.",
    "price": 38.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP06.png",
    "link": "https://amzn.to/3332Xdu",
    "Notes": "This is a great extra item for those who are particularly neat, and for puppies who start to chew up their pee pads. It clamps down around the potty pad, holding it neatly in place."
  },
  {
    "ID": "EQP07",
    "name": "Ziwi Peak Training Treats",
    "description": "These treats are delicious and nutritious, small enough for training, and a great option for your puppy. ",
    "price": 9.78,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP07.png",
    "link": "https://amzn.to/3mXx64R",
    "Notes": "These treats are delicious and nutritious, and a great option for your puppy. "
  },
  {
    "ID": "EQP08",
    "name": "VIVAGLORY Dog Leash",
    "description": "Your first leash should have a sturdy safety clip, but also be inexpensive and light, so that your puppy can get used to the leash. ",
    "price": 13.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP08.png",
    "link": "https://amzn.to/3yZEHoe",
    "Notes": "Your first leash should have a sturdy safety clip, but also be inexpensive and light, so that your small puppy can get used to the leash. "
  },
  {
    "ID": "EQP09",
    "name": "Ruffwear Front Range Harness",
    "description": "Harnesses are good for your dog's body, because they keep pressure off of their throat while they are learning to walk on leash. Be sure to check sizing and to introduce your pup to it slowly and with treats.",
    "price": 39.95,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP09.png",
    "link": "https://amzn.to/3FUvOPH",
    "Notes": "Harnesses are good for your dog's body, because they keep pressure off of their throat while they are learning to walk on leash. Be sure to check sizing and to introduce your pup to it slowly and with treats."
  },
  {
    "ID": "EQP10",
    "name": "Hydrapeak Dog Bowl",
    "description": "Any bowl will do, but this is an aesthetic favorite from our team",
    "price": 27.95,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP10.png",
    "link": "https://amzn.to/3pTA8sR",
    "Notes": "Any bowl will do, but tech bowls are more fun."
  },
  {
    "ID": "EQP11",
    "name": "Kong Classic",
    "description": "You'll want several kongs. They are the most essential training toy on the market. Start with a couple of small ones, and buy larger kongs as your dog grows. ",
    "price": 7.99,
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/equipment/EQP11.png",
    "link": "https://amzn.to/3pT9Ir3",
    "Notes": "You'll want several kongs. They are the most essential training toy on the market. Start with a couple of small ones, and buy larger kongs as your dog grows. "
  }
]

function Equipment() {
  return equipment
}

export default Equipment()