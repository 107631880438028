import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg'
import { ReactComponent as TOCIcon } from '../../assets/toc_icon.svg'
import { ReactComponent as Star } from '../../assets/star.svg'
import SvgIcon from '@mui/material/SvgIcon'
import TransitionCard from './TransitionCard'
import { Themes, ThemeNames } from './themes'
import { DESKTOP_WIDTH } from '../constants'

export default function CheckInCard({
    question,
    response_a,
    response_b,
    answer_a,
    answer_b,
    body_a,
    chat_path_id,
    next,
    prev,
    setCurrentCard,
}) {
    let theme_names = ThemeNames()
    let themes = Themes()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const [currentTheme] = React.useState(localStorage.getItem("theme") || Math.floor(Math.random() * theme_names.length))

    return (
        <div style={{ overflow: 'hidden' }}>
            {showSuccess && <TransitionCard Headline={answer_a} next={next} prev={prev} setCurrentCard={setCurrentCard} color={themes[theme_names[currentTheme]].backgroundColor} />}
            {!showSuccess && (
                <Box
                    className={'notch-space real-full-height'}
                    sx={{
                        height: '100%',
                        width: '100%',
                        maxWidth: DESKTOP_WIDTH,
                        position: 'fixed',
                        zIndex: 100,
                        backgroundImage: themes[theme_names[currentTheme]].backgroundImage,
                        backgroundSize: '50%',
                        backgroundRepeat: 'repeat',
                        backgroundColor: themes[theme_names[currentTheme]].backgroundColor,
                    }}
                >
                    <Grid container sx={{ zIndex: 1 }}>
                        <Grid item sx={{ padding: '24px', zIndex: 1 }} xs={6} justifyContent="center" alignItems="center">
                            <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(prev)}>
                                <Typography
                                    sx={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        fontFamily: 'buena-park-jf',
                                        color: themes[theme_names[currentTheme]].textColor,
                                        textDecoration: 'none',
                                    }}
                                    align="left"
                                    variant="h5"
                                    gutterBottom
                                    component="div"
                                >
                                    <SvgIcon
                                        sx={{
                                            width: 24,
                                            height: 16,
                                            path: { fill: themes[theme_names[currentTheme]].textColor },
                                        }}
                                        component={BackIcon}
                                        viewBox="0 0 24 17"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            justifyContent="end"
                            alignItems="end"
                            sx={{ textAlign: 'right', padding: '24px', zIndex: 1 }}
                        >
                            <Box style={{ textDecoration: 'none' }} onClick={() => setCurrentCard(-2)}>
                                <Typography
                                    sx={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        fontFamily: 'buena-park-jf',
                                        color: themes[theme_names[currentTheme]].textColor,
                                        textDecoration: 'none',
                                    }}
                                    align="right"
                                    variant="h5"
                                    gutterBottom
                                    component="div"
                                >
                                    <SvgIcon
                                        sx={{
                                            width: 17,
                                            height: 21,
                                            path: { fill: themes[theme_names[currentTheme]].textColor },
                                        }}
                                        component={TOCIcon}
                                        viewBox="0 0 17 21"
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ zIndex: 1, padding: '24px' }}>
                            <Grid item xs={12} justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
                                <SvgIcon
                                    sx={{
                                        width: 24,
                                        height: 36,
                                        path: { fill: themes[theme_names[currentTheme]].textColor },
                                    }}
                                    component={Star}
                                    viewBox="0 0 24 36"
                                />
                            </Grid>
                            <Typography
                                sx={{
                                    color: themes[theme_names[currentTheme]].textColor,
                                    fontWeight: 300,
                                    fontSize: 30,
                                    fontFamily: 'buena-park-jf',
                                }}
                                align="center"
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                {question}
                            </Typography>
                            <Typography
                                sx={{
                                    color: themes[theme_names[currentTheme]].textColor,
                                    fontWeight: 400,
                                    fontSize: 16,
                                    fontFamily: 'basic-sans',
                                    lineHeight: '24px',
                                    paddingTop: '24px',
                                    paddingRight: '36px',
                                    paddingLeft: '36px'
                                }}
                                align="center"
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                {body_a}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sx={{ zIndex: 1, padding: '24px' }}>
                            <Button
                                onClick={() => setShowSuccess(true)}
                                sx={{
                                    width: '100%',
                                    backgroundColor: themes[theme_names[currentTheme]].buttonBg,
                                    borderRadius: 0,
                                    fontFamily: 'buena-park-jf',
                                    color: themes[theme_names[currentTheme]].buttonTextColor,
                                    fontSize: 18,
                                    padding: '12px 48px',
                                    fontWeight: 300,
                                    boxShadow: 'none',
                                    textTransform: 'Capitalize',
                                    '&:hover': {
                                        backgroundColor: themes[theme_names[currentTheme]].buttonBg,
                                    },
                                }}
                                disableRipple
                                variant="contained"
                            >
                                {response_a}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ zIndex: 1, padding: '24px' }}>
                            <Button
                                onClick={() => {
                                    window._slaask.startConversation({ botTree: chat_path_id, contactCommitted: true })
                                    window._slaask.show()
                                }}
                                sx={{
                                    width: '100%',
                                    backgroundColor: themes[theme_names[currentTheme]].buttonBg,
                                    borderRadius: 0,
                                    fontFamily: 'buena-park-jf',
                                    color: themes[theme_names[currentTheme]].buttonTextColor,
                                    fontSize: 18,
                                    padding: '12px 48px',
                                    fontWeight: 300,
                                    boxShadow: 'none',
                                    textTransform: 'Capitalize',
                                    '&:hover': {
                                        backgroundColor: themes[theme_names[currentTheme]].buttonBg,
                                    },
                                }}
                                disableRipple
                                variant="contained"
                            >
                                {response_b}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    )
}
