import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import Box from '@mui/material/Box'
import StandardBottomNavigation from './components/navigation/StandardBottomNavigation.js'
import Grid from '@mui/material/Grid'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    if (!window.LOGGED_IN) {
      navigate('/locked')
    }
  }, [])

  return (
    <Box sx={{ pb: 7 }}>
      <ScopedCssBaseline>
        <Grid container spacing={0} alignItems="center" sx={{ bgcolor: 'white', width: '100%' }}>
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>

        <StandardBottomNavigation />
      </ScopedCssBaseline>
    </Box>
  )
}

export default App
