import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import lottie from 'lottie-web'
import LogoIcon from '../../assets/LoadingLogo.png'
import common_lottie from '../../assets/loader.json'
import loading_lottie from '../../assets/themed_loader.json'
import { DESKTOP_WIDTH } from '../constants'
import brown_texture from '../../assets/tex_hound.png'
import green_texture from '../../assets/tex_green.png'
import yellow_texture from '../../assets/tex_yellow.png'

export default function TransitionCard({ Headline, BodyCopy, next, prev, setCurrentCard, backbutton, color }) {
  React.useEffect(() => {
    let animation = lottie.loadAnimation({
      container: document.querySelector('#lottie-loader'),
      animationData: color ? loading_lottie : common_lottie,
      renderer: 'svg', // "canvas", "svg"
      loop: false, // boolean
      autoplay: true, // boolean,
    })

    animation.onComplete = () => {
      setCurrentCard(next)
    }
  }, [next])

  const bg_image = color === "#392D2C" ? brown_texture : color === "#EFA329" ? yellow_texture : green_texture;

  return (
    <Grid
      className={'notch-space real-full-height '}
      justifyContent="center"
      sx={{
        backgroundImage: `url(${bg_image})`,
        height: '100%',
        width: '100%',
        maxWidth: DESKTOP_WIDTH,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: color || '#FFA487',
        overflow: 'hidden',
      }}
    >
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "90px", padding: ' 24px', height: "150px", textAlign: 'center' }}
      >
        <img alt="Gentle Beast" style={{ height: 52, fill: '##F46E4A', margin: "0 auto", position: 'relative' }} src={LogoIcon} />
      </Grid>
      <Grid justifyContent="center" alignItems="center" item xs={12} sx={{ zIndex: 1, marginTop: "80px", padding: '24px', paddingBottom: 'calc(env(safe-area-inset-top) * 2)' }}>
        <div id="lottie-loader" style={{ width: 25, height: 25, margin: '0 auto', position: 'relative' }} />
        <Typography
          sx={{ color: color ? '#FFF' : '#392D2C', fontWeight: 700, fontSize: 30, fontFamily: 'buena-park-jf' }}
          align="center"
          variant="h5"
          gutterBottom
          component="div"
        >
          {Headline}
        </Typography>
        <Typography sx={{ color: color ? '#FFF' : '#392D2C', fontSize: 16 }} align="center" variant="h5" gutterBottom component="div">
          {BodyCopy}
        </Typography>
      </Grid>
    </Grid>
  )
}
