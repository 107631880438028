import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Courses from './routes/courses'
import Lessons from './routes/lessons'
import Lesson from './routes/lesson'
import Shop from './routes/shop'
import Locked from './routes/locked'
import Onboarding from './routes/onboarding'
import StuffYouNeed from './components/chapter_templates/StuffYouNeed'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

if (window?.screen) {
  window.screen?.orientation?.lock("landscape")
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/locked" element={<Locked />} />

        <Route element={<App />}>
          <Route index element={<Navigate replace to="/onboarding" />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route exact path="shop" element={<Shop />} />
          <Route path="shop/checkList" element={<StuffYouNeed />} />
          <Route path="courses" element={<Courses />} />
          <Route path="course/:courseId/lessons" element={<Lessons />} />
          <Route path="course/:courseId/lessons/:lessonId" element={<Lesson />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

var currentVersion = process.env.REACT_APP_HEROKU_RELEASE_VERSION

if (process.env.NODE_ENV === 'production') {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      fetch(`/index.html?_=${Date.now()}`)
        .then((res) => res.text())
        .then((htmlString) => {
          let doc = new DOMParser().parseFromString(htmlString, 'text/html')
          let latestVersion = doc.querySelector("meta[name='app-version']").getAttribute('content')

          if (latestVersion !== currentVersion) {
            window.location.reload(true)
          }
        })
    }
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
