let chapter_intros = [
  {
    "Page": "Tutorial",
    "ID": "CHP00",
    "headline": "Quick Warmup: Touch",
    "body": "Let's get you familiar with the Gentle Beast platform with a quick and easy lesson. The touch cue is one of the most foundational and useful training cues you and your dog can learn together. In this short lesson, we'll break down the different steps in short clips that you can pause and repeat with just a tap – so you and your dog can learn at your own speed.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP00_warmup.png"
  },
  {
    "Page": "Chapter 01",
    "ID": "CHP01",
    "headline": "Lesson Overview",
    "body": "Ready to get started? In this first chapter, you'll hear from our Chief Training Officer, Amanda Gagnon (MS, CDBC, CDPT-KA) about why house training is such an important step in building a better relationship with your dog. But the instruction isn't all of it – we'll also share our recommendations for the best products and equipment you can buy to set both you and your dog up for success.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP01_lessonoverview.png"
  },
  {
    "Page": "Chapter 02",
    "ID": "CHP02",
    "headline": "Preparation Lessons",
    "body": "Now that you've got a bit of context for house training, and you know what tools to use, it's time to put them to work. In this short chapter, we'll walk through the key uses of these tools – and yes, sometimes that's going to be cleaning up accidents! ",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP02_preplessons.png"
  },
  {
    "Page": "Chapter 03",
    "ID": "CHP03",
    "headline": "The Three Rules",
    "body": "Oh yes, there are rules to house training – and these rules are for humans, not for dogs. In this chapter, Amanda breaks down these three rules that make for successful house training: reward success, prevent mistakes, and later – schedule, schedule, schedule.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP03_threerules.png"
  },
  {
    "Page": "Chapter 04",
    "ID": "CHP04",
    "headline": "Reward Success",
    "body": "Now that you know the three rules of house training, it's time to break down how to put these rules into action. First up: Reward Success. We'll show you how to consistently and quickly get your dog to associate a successful potty with praise and treats.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP04_rewardsuccess.png"
  },
  {
    "Page": "Chapter 05",
    "ID": "CHP05",
    "headline": "Prevent Mistakes",
    "body": "On to rule number two: prevent mistakes. Mistakes are going to happen – it's just part of the process. But there's a few things you can do to minimize mistakes – and the more you do that, the faster your dog will learn.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP05_preventmistakes.png"
  },
  {
    "Page": "Chapter 06",
    "ID": "CHP06",
    "headline": "Scheduling",
    "body": "Now for the third, and arguably most important, rule of house training. In this chapter, Amanda will walk you through how to build a consistent schedule and why it's critical to your success. ",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP06_scheduling.png"
  },
  {
    "Page": "Chapter 07",
    "ID": "CHP07",
    "headline": "Daytime",
    "body": "There are two schedules you'll need to stick to: Daytime, and Nighttime. In this chapter, we'll walk through the different blocks of time and what you should be doing during the day.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP07_daytime.png"
  },
  {
    "Page": "Chapter 08",
    "ID": "CHP08",
    "headline": "Nighttime",
    "body": "Now that we've learned how to build a consistent schedule for the daytime, let's take a look at a nighttime schedule. Warning, this does include getting up in the middle of the night. But, the more consistent you are, the sooner you'll be able to go back to sawing wood.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP08_nightime.png"
  },
  {
    "Page": "Chapter 09",
    "ID": "CHP09",
    "headline": "Finishing",
    "body": "Woo! You're nearly there. Now that you have the tools and guidance to successfully house train your dog, it's time to put it all together. In this final chapter, Amanda will walk through the final steps of ensuring your dog is completely house trained – including when you're headed to an exciting new space!",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP09_finishing.png"
  },
  {
    "Page": "Chapter 09B",
    "ID": "CHP09B",
    "headline": "Transition to Ouside",
    "body": "Now, we don't want your dog peeing inside forever – your potty pad costs will go through the roof! In this next chapter, we'll walk through how to slowly transition your dog to going outside with minimal interruption.",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP09B_transition.png"
  },
  {
    "Page": "Chapter 10",
    "ID": "CHP10",
    "headline": "Next Steps",
    "body": "Woo! You're nearly there. Now that you have the tools and guidance to successfully house train your dog, it's time to put it all together. In this final chapter, Amanda will walk through the final steps of ensuring your dog is completely house trained – including when you're headed to an exciting new space!",
    "buttonText": "CONTINUE",
    "image": "https://gentlebeastbeta.s3.us-east-2.amazonaws.com/chapters/CHP10_finishing.png"
  }
]

function ChapterIntros() {
  return chapter_intros
}

export default ChapterIntros()
