import * as React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PickerDropPane } from 'filestack-react'

import { ReactComponent as BackIcon } from '../assets/back_icon.svg'
import { ReactComponent as EquipmentIcon } from '../assets/equipment_icon.svg'
import { ReactComponent as CheckedIcon } from '../assets/checked_icon.svg'
import { ReactComponent as UncheckedIcon } from '../assets/unchecked_icon.svg'
import { ReactComponent as EditIcon } from '../assets/edit_icon.svg'
import { ReactComponent as CloseIcon } from '../assets/close_icon.svg'
import { ReactComponent as FootprintIcon } from '../assets/footprint.svg'
import { ReactComponent as LessonIcon } from '../assets/toc_icon.svg'
import green_texture from '../assets/tex_green.png'
import SvgIcon from '@mui/material/SvgIcon'
import { getCardByID } from '../courses_config/data.js'
import { DEFAULT_IMG } from './constants'

export default function LessonTOC({
  coursePath,
  card_progress,
  lesson,
  setCurrentChapter,
  clearLocalStorage,
  skipToChapter,
}) {
  let params = useParams()
  const navigate = useNavigate()
  const [showPicker, setShowPicker] = React.useState(false)
  let lastChapter = localStorage.getItem(`${params.courseId}__${params.lessonId}_current_chapter`)
  const [chapterState, setChapterState] = React.useState(lastChapter ? parseInt(lastChapter) : 0)
  let onboardingState = localStorage.getItem('onboarding')
  onboardingState = JSON.parse(onboardingState)
  React.useEffect(() => {
    let chapter = localStorage.getItem(`${params.courseId}__${params.lessonId}_current_chapter`)
    setChapterState(chapter ? parseInt(chapter) : 0)
  }, [card_progress, params.courseId, params.lessonId])

  const getChapterImage = (chapter, index) => {
    let card = getCardByID(chapter.chapter_cards[0]['ID'])
    return card.image
  }

  const updateImage = (res) => {
    if (res.filesUploaded.length > 0) {
      let img = res.filesUploaded[0].url

      let newState = { ...onboardingState }
      newState['image'] = img
      localStorage.setItem('onboarding', JSON.stringify(newState))
      if (window.memberstack_member) {
        window.memberstack_member.updateMetaData(newState)
      }
      onboardingState = newState;
      setShowPicker(false);
    }
  }

  return (
    <Grid container sx={{ height: '100vh', backgroundColor: 'rgba(228,219,198,1)' }}>
      <Grid item xs={12} container sx={{ position: 'relative' }}>
        <Grid container sx={{ position: 'absolute', left: 0, top: 0, right: 0 }}>
          <Grid item xs={2} justifyContent="center" alignItems="center" sx={{ padding: '24px 24px 48px 24px', zIndex: 1 }}>
            {false && (
              <Link style={{ textDecoration: 'none' }} to={coursePath}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                    fontFamily: 'serif',
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  align="left"
                  variant="h5"
                  gutterBottom
                  component="div"
                >
                  <SvgIcon
                    sx={{ width: 24, height: 16, path: { fill: 'white' } }}
                    component={BackIcon}
                    viewBox="0 0 24 17"
                  />
                </Typography>
              </Link>
            )}
          </Grid>
          {
            showPicker ? <>
              <Grid
                sx={{ padding: '24px 24px 48px 24px', zIndex: 1, textAlign: 'right' }}
                item
                xs={10}
                justifyContent="right"
                alignItems="end"
              >
                <Box style={{ textDecoration: 'none' }} onClick={() => setShowPicker(false)}>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 700,
                      fontFamily: 'buena-park-jf',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                    align="right"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    <SvgIcon
                      sx={{ width: 33, height: 33, path: { fill: 'white' } }}
                      component={CloseIcon}
                      viewBox="0 0 33 33"
                    />
                  </Typography>
                </Box>
              </Grid>
              <span style={{ width: "100%", display: "flex", justifyContent: "center", zIndex: 1000 }}>
                <PickerDropPane
                  onSuccess={updateImage}
                  pickerOptions={{
                    accept: 'image/*',
                    dropPane: { customText: 'choose an image' },
                  }}
                  apikey="AWevDf9qqSgueCtcXmXhKz"
                />
              </span>
            </>
              :
              <Grid
                sx={{ padding: '24px 24px 48px 24px', zIndex: 1, textAlign: 'right' }}
                item
                xs={10}
                justifyContent="right"
                alignItems="end"
              >
                <Box style={{ textDecoration: 'none' }} onClick={() => setShowPicker(true)}>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 700,
                      fontFamily: 'buena-park-jf',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                    align="right"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    <SvgIcon
                      sx={{ width: 33, height: 33, path: { fill: 'white' } }}
                      component={EditIcon}
                      viewBox="0 0 33 33"
                    />
                  </Typography>
                </Box>
              </Grid>
          }
        </Grid>
        <img
          alt="Your Dog"
          height={"300px"}
          src={onboardingState['image'] ?? DEFAULT_IMG}
          width="100%"
          style={{
            top: 0,
            objectFit: 'cover',
            objectPosition: 'center center',
            opacity: 1,
            overflow: 'hidden',
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{ zIndex: 1 }}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: 'rgba(228,219,198,1)',
            borderRadius: 0,
            paddingBottom: '100px',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                backgroundImage: `url(${green_texture})`,
                backgroundRepeat: 'repeat',
                backgroundColor: '#2F4818',
                padding: '24px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'basic-sans',
                  fontSize: 16,
                  color: 'rgba(228,219,198,1)',
                  fontWeight: 700,
                }}
                align="left"
                variant="p"
                gutterBottom
                component="div"
              >
                PUPPY KINDERGARTEN
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(228,219,198,1)',
                  fontWeight: 300,
                  fontSize: 32,
                  fontFamily: 'buena-park-jf',
                }}
                align="left"
                variant="h5"
                gutterBottom
                component="div"
              >
                {lesson.title}
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(228,219,198,1)',
                  fontWeight: 500,
                  fontSize: 14,
                  fontFamily: 'basic-sans',
                }}
                align="left"
                variant="h5"
                gutterBottom
                component="div"
              >
                {lesson.description}
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ padding: '24px' }}>
              <Button
                onClick={() => setCurrentChapter(chapterState >= 0 ? chapterState : 0)}
                sx={{
                  width: '100%',
                  backgroundColor: '#F46E4A',
                  borderRadius: 0,
                  fontFamily: 'basic-sans',
                  color: '#392D2C',
                  fontSize: 14,
                  padding: '12px 48px',
                  fontWeight: 700,
                  textTransform: 'initial',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#F46E4A',
                  },
                }}
                disableRipple
                variant="contained"
              >
                {chapterState === 0 || chapterState === -1
                  ? 'Start Now'
                  : `Continue Chapter ${parseInt(chapterState) + 1}`}
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ padding: '0 24px 0px 24px' }}>
              <Grid container justifyContent="space-between">
                <Button
                  startIcon={<SvgIcon
                    sx={{ width: 20, height: 22, path: { fill: '#2F4818' } }}
                    component={EquipmentIcon}
                    viewBox="0 0 20 22"
                  />}
                  sx={{
                    color: '#2F4818',
                    fontWeight: 700,
                    fontFamily: 'basic-sans',
                    fontSize: 14,
                    lineHight: 22,
                    padding: 0,
                    textTransform: 'capitalize'
                  }}
                  onClick={() => navigate("/shop/checkList")}
                >
                  Equipment Checklist
                </Button>
              </Grid>
            </Grid>

            <Grid container sx={{ padding: '12px 24px' }}>
              {lesson.chapters.map((chapter, index) => (
                <Grid
                  item
                  xs={12}
                  sx={{ padding: '6px 0 0 0' }}
                  key={`chapter-${index}`}
                  onClick={() => skipToChapter(index)}
                >
                  <Grid container align="center">
                    <Grid item xs={3} align="start">
                      <img
                        height={'50px'}
                        width={'70px'}
                        alt="your dog"
                        src={getChapterImage(chapter, index)}
                        style={{ objectFit: 'cover' }}
                      />
                    </Grid>
                    <Grid item xs={7} align="start" sx={{ paddingLeft: '6px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'basic-sans',
                          color: '#392D2C',
                          fontWeight: 700,
                          fontSize: 14,
                          textDecoration: 'none',
                        }}
                        variant="h5"
                        gutterBottom
                        component="div"
                      >
                        {`Chapter ${index + 1}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'basic-sans',
                          color: '#392D2C',
                          fontWeight: 300,
                          fontSize: 14,
                          textDecoration: 'none',
                        }}
                        variant="h5"
                        gutterBottom
                        component="div"
                      >
                        {chapter.chapter_title}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {card_progress[index] ? (
                        <SvgIcon
                          sx={{ width: 32, height: 32, path: { fill: '#392D2C' } }}
                          component={CheckedIcon}
                          viewBox="0 0 32 32"
                        />
                      ) : (
                        <SvgIcon
                          sx={{ width: 32, height: 32, path: { fill: '#392D2C' } }}
                          component={UncheckedIcon}
                          viewBox="0 0 32 32"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
