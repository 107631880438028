let transitions = [
  {
    "Page": "Transition 01 Onboarding Intro",
    "ID": "TRN01",
    "Headline": "Welcome to Gentle Beast"
  },
  {
    "Page": "Transition 02 Onboarding Outro",
    "ID": "TRN02",
    "Headline": "Personalizing your training plan..."
  },
  {
    "Page": "Transition 03 Course Intro",
    "ID": "TRN03",
    "Headline": "Loading your lesson..."
  }
]

function Transitions() {
  return transitions
}

export default Transitions()
